// GENERATED BY graphql-code-generator. DO NOT EDIT
// tslint:disable

import gql from "graphql-tag";
import * as ReactApollo from "react-apollo";
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Uuid */
  Uuid: any;
  /** DateTime */
  DateTimeUtc: any;
  /** Naive time in the format HH:MM:SS */
  NaiveTime: any;
  Minutes: any;
};

export type BeaconRegion = {
  __typename?: "BeaconRegion";
  readonly monaBeaconRegionTriggerId: Scalars["String"];
};

export type ChangeDynamicQueueCloseTimeInput = {
  readonly queueId: Scalars["Uuid"];
  readonly closeTime: Scalars["NaiveTime"];
};

export type ChangeDynamicQueueCloseTimeResult = {
  __typename?: "ChangeDynamicQueueCloseTimeResult";
  readonly queue: DynamicQueue;
};

export type ChangeSlottedQueueScheduledLastSlotStartTimeInput = {
  readonly queueId: Scalars["Uuid"];
  readonly startTime: Scalars["NaiveTime"];
};

export type DynamicQueue = Queue & {
  __typename?: "DynamicQueue";
  readonly id: Scalars["Uuid"];
  readonly name: Scalars["String"];
  readonly shortName?: Maybe<Scalars["String"]>;
  readonly locationName: Scalars["String"];
  readonly monaObjectId?: Maybe<Scalars["String"]>;
  readonly title: Scalars["String"];
  readonly joinQueueRegions?: Maybe<ReadonlyArray<Region>>;
  readonly nearbyRegions?: Maybe<ReadonlyArray<Region>>;
  readonly lobbyRegions?: Maybe<ReadonlyArray<Region>>;
  readonly state: DynamicQueueState;
  readonly openTime?: Maybe<Scalars["NaiveTime"]>;
  readonly closeTime?: Maybe<Scalars["NaiveTime"]>;
  readonly summonedEstimate?: Maybe<Scalars["Minutes"]>;
  readonly isFull: Scalars["Boolean"];
  readonly todaysQueueEntries?: Maybe<ReadonlyArray<DynamicQueueEntry>>;
};

/** A single entry in a queue, think of it like a ticket from the deli */
export type DynamicQueueEntry = QueueEntry & {
  __typename?: "DynamicQueueEntry";
  readonly id: Scalars["Uuid"];
  readonly session: Session;
  readonly createdAt: Scalars["DateTimeUtc"];
  readonly stateChangedAt: Scalars["DateTimeUtc"];
  readonly summonedEstimate?: Maybe<Scalars["Minutes"]>;
  readonly estimatedScore?: Maybe<Scalars["Float"]>;
  readonly numberOfPeople: Scalars["Int"];
  readonly shortCode: Scalars["String"];
  readonly queue: DynamicQueue;
  readonly state: DynamicQueueEntryState;
};

export enum DynamicQueueEntryState {
  Unqueued = "UNQUEUED",
  InVirtualQueue = "IN_VIRTUAL_QUEUE",
  SummonPending = "SUMMON_PENDING",
  Summoned = "SUMMONED",
  Postponed = "POSTPONED",
  Served = "SERVED",
  Completed = "COMPLETED",
  QueueCancelled = "QUEUE_CANCELLED",
  Expired = "EXPIRED",
  UserCancelled = "USER_CANCELLED"
}

export type DynamicQueueEntryTransitionInput = {
  readonly queueEntryId: Scalars["Uuid"];
};

export type DynamicQueueEntryTransitionResult = {
  __typename?: "DynamicQueueEntryTransitionResult";
  readonly queueEntry: DynamicQueueEntry;
  readonly errorMessage?: Maybe<Scalars["String"]>;
};

export enum DynamicQueueState {
  Open = "OPEN",
  Closed = "CLOSED",
  Cancelled = "CANCELLED"
}

export type DynamicQueueTransitionInput = {
  readonly queueId: Scalars["Uuid"];
};

export type DynamicQueueTransitionResult = {
  __typename?: "DynamicQueueTransitionResult";
  readonly queue: DynamicQueue;
};

export type ForceSummonNextDynamicQueueEntryInput = {
  readonly queueId: Scalars["Uuid"];
};

export type ForceSummonNextDynamicQueueEntryResult = {
  __typename?: "ForceSummonNextDynamicQueueEntryResult";
  readonly queueEntry?: Maybe<DynamicQueueEntry>;
};

export enum JumpState {
  Offered = "OFFERED",
  Accepted = "ACCEPTED",
  Rejected = "REJECTED",
  Expired = "EXPIRED"
}

export type LocateSpace = {
  __typename?: "LocateSpace";
  readonly id: Scalars["String"];
  readonly name: Scalars["String"];
};

export type Mutations = {
  __typename?: "Mutations";
  readonly forceSummonDynamicQueueEntry: DynamicQueueEntryTransitionResult;
  readonly serveDynamicQueueEntry: DynamicQueueEntryTransitionResult;
  readonly cancelDynamicQueueEntry: DynamicQueueEntryTransitionResult;
  readonly cancelDynamicQueue: DynamicQueueTransitionResult;
  readonly openDynamicQueue: DynamicQueueTransitionResult;
  readonly forceSummonNextDynamicQueueEntry: ForceSummonNextDynamicQueueEntryResult;
  readonly changeDynamicQueueCloseTime: ChangeDynamicQueueCloseTimeResult;
  readonly cancelSlottedQueue: SlottedQueueTransitionResult;
  readonly setSlottedQueueMode: SlottedQueueTransitionResult;
  readonly changeSlottedQueueScheduledLastSlotStartTime: SlottedQueueTransitionResult;
  readonly setSlottedQueueSlotMode: SlottedQueueSlotTransitionResult;
  readonly setSlottedQueueSlotAttributes: SlottedQueueSlotTransitionResult;
  readonly reassignSlottedQueueEntry: SlottedQueueTransitionResult;
  readonly reassignSlottedQueueTickets: SlottedQueueTransitionResult;
};

export type MutationsForceSummonDynamicQueueEntryArgs = {
  input: DynamicQueueEntryTransitionInput;
};

export type MutationsServeDynamicQueueEntryArgs = {
  input: DynamicQueueEntryTransitionInput;
};

export type MutationsCancelDynamicQueueEntryArgs = {
  input: DynamicQueueEntryTransitionInput;
};

export type MutationsCancelDynamicQueueArgs = {
  input: DynamicQueueTransitionInput;
};

export type MutationsOpenDynamicQueueArgs = {
  input: DynamicQueueTransitionInput;
};

export type MutationsForceSummonNextDynamicQueueEntryArgs = {
  input: ForceSummonNextDynamicQueueEntryInput;
};

export type MutationsChangeDynamicQueueCloseTimeArgs = {
  input: ChangeDynamicQueueCloseTimeInput;
};

export type MutationsCancelSlottedQueueArgs = {
  input: SlottedQueueTransitionInput;
};

export type MutationsSetSlottedQueueModeArgs = {
  input: SlottedQueueModeInput;
};

export type MutationsChangeSlottedQueueScheduledLastSlotStartTimeArgs = {
  input: ChangeSlottedQueueScheduledLastSlotStartTimeInput;
};

export type MutationsSetSlottedQueueSlotModeArgs = {
  input: SlottedQueueSlotTransitionInput;
};

export type MutationsSetSlottedQueueSlotAttributesArgs = {
  input: SlottedQueueSlotAttributesInput;
};

export type MutationsReassignSlottedQueueEntryArgs = {
  input: SlottedQueueReassignQueueEntryInput;
};

export type MutationsReassignSlottedQueueTicketsArgs = {
  input: SlottedQueueReassignTicketsInput;
};

export type NfcTagRegion = {
  __typename?: "NfcTagRegion";
  readonly nfcTagId: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  readonly viewer?: Maybe<UserSession>;
  readonly now: Scalars["DateTimeUtc"];
  readonly dynamicQueue?: Maybe<DynamicQueue>;
  readonly dynamicQueues: ReadonlyArray<DynamicQueue>;
  readonly dynamicQueueEntry?: Maybe<DynamicQueueEntry>;
  readonly slottedQueue?: Maybe<SlottedQueue>;
  readonly slottedQueues: ReadonlyArray<SlottedQueue>;
  readonly slottedQueueEntry?: Maybe<SlottedQueueEntry>;
  readonly session?: Maybe<Session>;
};

export type QueryDynamicQueueArgs = {
  id: Scalars["Uuid"];
};

export type QueryDynamicQueueEntryArgs = {
  id: Scalars["Uuid"];
};

export type QuerySlottedQueueArgs = {
  id: Scalars["Uuid"];
};

export type QuerySlottedQueueEntryArgs = {
  id: Scalars["Uuid"];
};

export type QuerySessionArgs = {
  id: Scalars["Uuid"];
};

export type Queue = {
  __typename?: "Queue";
  readonly id: Scalars["Uuid"];
  readonly name: Scalars["String"];
  readonly shortName?: Maybe<Scalars["String"]>;
  readonly locationName: Scalars["String"];
  readonly monaObjectId?: Maybe<Scalars["String"]>;
  readonly title: Scalars["String"];
  readonly joinQueueRegions?: Maybe<ReadonlyArray<Region>>;
  readonly nearbyRegions?: Maybe<ReadonlyArray<Region>>;
  readonly lobbyRegions?: Maybe<ReadonlyArray<Region>>;
};

export type QueueEntry = {
  __typename?: "QueueEntry";
  readonly id: Scalars["Uuid"];
  readonly session: Session;
  readonly createdAt: Scalars["DateTimeUtc"];
  readonly stateChangedAt: Scalars["DateTimeUtc"];
  readonly summonedEstimate?: Maybe<Scalars["Minutes"]>;
};

export type Region = BeaconRegion | SpaceRegion | NfcTagRegion;

export type Session = {
  __typename?: "Session";
  readonly id: Scalars["Uuid"];
  readonly metadataOsVersion?: Maybe<Scalars["String"]>;
  readonly metadataAppVersion?: Maybe<Scalars["String"]>;
  readonly metadataDeviceRole?: Maybe<Scalars["String"]>;
  readonly latestSessionInfo?: Maybe<SessionInfo>;
  readonly activeDynamicQueueEntries: ReadonlyArray<DynamicQueueEntry>;
  readonly activeSlottedQueueEntries: ReadonlyArray<SlottedQueueEntry>;
  readonly isBusy: Scalars["Boolean"];
};

export type SessionInfo = {
  __typename?: "SessionInfo";
  readonly id: Scalars["Uuid"];
  readonly createdAt: Scalars["DateTimeUtc"];
  readonly batteryLevel: Scalars["Float"];
  readonly locateSpaces: ReadonlyArray<LocateSpace>;
};

export enum SlotMode {
  Default = "DEFAULT",
  Cancelled = "CANCELLED",
  Paused = "PAUSED"
}

export type SlottedQueue = Queue & {
  __typename?: "SlottedQueue";
  readonly id: Scalars["Uuid"];
  readonly name: Scalars["String"];
  readonly shortName?: Maybe<Scalars["String"]>;
  readonly locationName: Scalars["String"];
  readonly monaObjectId?: Maybe<Scalars["String"]>;
  readonly title: Scalars["String"];
  readonly joinQueueRegions?: Maybe<ReadonlyArray<Region>>;
  readonly nearbyRegions?: Maybe<ReadonlyArray<Region>>;
  readonly lobbyRegions?: Maybe<ReadonlyArray<Region>>;
  readonly mode: SlottedQueueMode;
  readonly openTime: Scalars["NaiveTime"];
  readonly closeTime: Scalars["NaiveTime"];
  readonly scheduledLastSlotStartTime: Scalars["NaiveTime"];
  readonly closeTimeToday: Scalars["DateTimeUtc"];
  readonly scheduledLastSlotStartTimeToday: Scalars["DateTimeUtc"];
  readonly slotIntervalSeconds: Scalars["Int"];
  readonly exhibitCapacityPeople: Scalars["Int"];
  readonly todaysQueueEntries?: Maybe<ReadonlyArray<SlottedQueueEntry>>;
  readonly todaysSlots: ReadonlyArray<SlottedQueueSlot>;
  readonly todaysUnallocatedQueueEntries: ReadonlyArray<SlottedQueueEntry>;
  readonly servedTodayCount: Scalars["Int"];
  readonly totalTicketsTodayCount: Scalars["Int"];
  readonly claimedUnservedTicketsTodayCount: Scalars["Int"];
  readonly remainingUnclaimedTicketsTodayCount: Scalars["Int"];
  readonly remainingSeatsNext2HoursCount: Scalars["Int"];
  readonly seatsRemainingTodayCount: Scalars["Int"];
};

/** A single entry in a queue, think of it like a ticket from the deli */
export type SlottedQueueEntry = QueueEntry & {
  __typename?: "SlottedQueueEntry";
  readonly id: Scalars["Uuid"];
  readonly session: Session;
  readonly createdAt: Scalars["DateTimeUtc"];
  readonly stateChangedAt: Scalars["DateTimeUtc"];
  readonly summonedEstimate?: Maybe<Scalars["Minutes"]>;
  readonly numberOfPeople: Scalars["Int"];
  readonly shortCode: Scalars["String"];
  readonly queue: SlottedQueue;
  readonly state: SlottedQueueEntryState;
  readonly slot?: Maybe<SlottedQueueSlot>;
  readonly tickets: ReadonlyArray<TessituraTicket>;
};

export enum SlottedQueueEntryState {
  Waiting = "WAITING",
  Allocated = "ALLOCATED",
  Served = "SERVED",
  Completed = "COMPLETED",
  UserCancelled = "USER_CANCELLED",
  QueueCancelled = "QUEUE_CANCELLED",
  TicketReclaimed = "TICKET_RECLAIMED",
  NoShowed = "NO_SHOWED"
}

export type SlottedQueueJump = {
  __typename?: "SlottedQueueJump";
  readonly id: Scalars["Uuid"];
  readonly state: JumpState;
  readonly offeredAt: Scalars["DateTimeUtc"];
  readonly resolvedAt?: Maybe<Scalars["DateTimeUtc"]>;
  readonly queueEntry: SlottedQueueEntry;
  readonly slot: SlottedQueueSlot;
};

export enum SlottedQueueMode {
  Open = "OPEN",
  Closed = "CLOSED",
  Paused = "PAUSED"
}

export type SlottedQueueModeInput = {
  readonly queueId: Scalars["Uuid"];
  readonly mode: SlottedQueueMode;
};

export type SlottedQueueReassignQueueEntryInput = {
  readonly queueEntryId: Scalars["Uuid"];
  readonly newSlotId: Scalars["Uuid"];
};

export type SlottedQueueReassignTicketsInput = {
  readonly ticketIds: ReadonlyArray<Scalars["Uuid"]>;
  readonly newSlotId: Scalars["Uuid"];
};

/** A time slot in a slotted queue */
export type SlottedQueueSlot = {
  __typename?: "SlottedQueueSlot";
  readonly id: Scalars["Uuid"];
  readonly queue: SlottedQueue;
  readonly startsAt: Scalars["DateTimeUtc"];
  readonly mode: SlotMode;
  readonly startsAfterQueueCloseTime: Scalars["Boolean"];
  readonly withinAllocationWindow: Scalars["Boolean"];
  readonly queueEntries: ReadonlyArray<SlottedQueueEntry>;
  readonly tickets: ReadonlyArray<TessituraTicket>;
  readonly unacknowledgedReassignedTickets: ReadonlyArray<
    UnacknowledgedReassignedTicket
  >;
  readonly offeredJumps: ReadonlyArray<SlottedQueueJump>;
  readonly notes: Scalars["String"];
  readonly reservedSeats: Scalars["Int"];
  readonly maxReservableSeats: Scalars["Int"];
  readonly availableSeatCount: Scalars["Int"];
};

export type SlottedQueueSlotAttributesInput = {
  readonly slotId: Scalars["Uuid"];
  readonly reservedSeats: Scalars["Int"];
  readonly notes: Scalars["String"];
};

export type SlottedQueueSlotTransitionInput = {
  readonly slotId: Scalars["Uuid"];
  readonly mode: SlotMode;
};

export type SlottedQueueSlotTransitionResult = {
  __typename?: "SlottedQueueSlotTransitionResult";
  readonly slot: SlottedQueueSlot;
};

export type SlottedQueueTransitionInput = {
  readonly queueId: Scalars["Uuid"];
};

export type SlottedQueueTransitionResult = {
  __typename?: "SlottedQueueTransitionResult";
  readonly queue: SlottedQueue;
};

export type SpaceRegion = {
  __typename?: "SpaceRegion";
  readonly ensoSpaceId: Scalars["String"];
};

export type TessituraOrder = {
  __typename?: "TessituraOrder";
  readonly id: Scalars["Uuid"];
  readonly orderNumber: Scalars["String"];
  readonly constituentEmail?: Maybe<Scalars["String"]>;
  readonly tickets: ReadonlyArray<TessituraTicket>;
};

export type TessituraTicket = {
  __typename?: "TessituraTicket";
  readonly id: Scalars["Uuid"];
  readonly ticketNumber: Scalars["String"];
  readonly order: TessituraOrder;
  readonly slottedQueueEntry?: Maybe<SlottedQueueEntry>;
};

/** A ticket that has been reassigned away from this slot, but has not yet been acknowledged by the user as being moved */
export type UnacknowledgedReassignedTicket = {
  __typename?: "UnacknowledgedReassignedTicket";
  readonly ticket: TessituraTicket;
  readonly newSlot: SlottedQueueSlot;
};

/** Represents a user logged into the web admin */
export type UserSession = {
  __typename?: "UserSession";
  readonly sessionId: Scalars["Uuid"];
};

export type ChangeDynamicQueueCloseTimeMutationVariables = {
  queueId: Scalars["Uuid"];
  closeTime: Scalars["NaiveTime"];
};

export type ChangeDynamicQueueCloseTimeMutation = {
  readonly __typename?: "Mutations";
} & {
  readonly changeDynamicQueueCloseTime: {
    readonly __typename?: "ChangeDynamicQueueCloseTimeResult";
  } & {
    readonly queue: {
      readonly __typename?: "DynamicQueue";
    } & DynamicQueueFragment;
  };
};

export type ChangeSlottedQueueScheduledLastSlotStartTimeMutationVariables = {
  queueId: Scalars["Uuid"];
  closeTime: Scalars["NaiveTime"];
};

export type ChangeSlottedQueueScheduledLastSlotStartTimeMutation = {
  readonly __typename?: "Mutations";
} & {
  readonly changeSlottedQueueScheduledLastSlotStartTime: {
    readonly __typename?: "SlottedQueueTransitionResult";
  } & {
    readonly queue: {
      readonly __typename?: "SlottedQueue";
    } & SlottedQueueFragment;
  };
};

export type ForceSummonDynamicQueueEntryMutationVariables = {
  queueEntryId: Scalars["Uuid"];
};

export type ForceSummonDynamicQueueEntryMutation = {
  readonly __typename?: "Mutations";
} & {
  readonly forceSummonDynamicQueueEntry: {
    readonly __typename?: "DynamicQueueEntryTransitionResult";
  } & {
    readonly queueEntry: { readonly __typename?: "DynamicQueueEntry" } & Pick<
      DynamicQueueEntry,
      "id" | "state" | "stateChangedAt"
    >;
  };
};

export type ServeDynamicQueueEntryMutationVariables = {
  queueEntryId: Scalars["Uuid"];
};

export type ServeDynamicQueueEntryMutation = {
  readonly __typename?: "Mutations";
} & {
  readonly serveDynamicQueueEntry: {
    readonly __typename?: "DynamicQueueEntryTransitionResult";
  } & {
    readonly queueEntry: { readonly __typename?: "DynamicQueueEntry" } & Pick<
      DynamicQueueEntry,
      "id" | "state" | "stateChangedAt"
    >;
  };
};

export type ForceSummonNextDynamicQueueEntryMutationVariables = {
  queueId: Scalars["Uuid"];
};

export type ForceSummonNextDynamicQueueEntryMutation = {
  readonly __typename?: "Mutations";
} & {
  readonly forceSummonNextDynamicQueueEntry: {
    readonly __typename?: "ForceSummonNextDynamicQueueEntryResult";
  } & {
    readonly queueEntry: Maybe<
      { readonly __typename?: "DynamicQueueEntry" } & ActiveQueueEntryFragment
    >;
  };
};

export type SetSlotModeMutationVariables = {
  slotId: Scalars["Uuid"];
  mode: SlotMode;
};

export type SetSlotModeMutation = { readonly __typename?: "Mutations" } & {
  readonly setSlottedQueueSlotMode: {
    readonly __typename?: "SlottedQueueSlotTransitionResult";
  } & {
    readonly slot: { readonly __typename?: "SlottedQueueSlot" } & {
      readonly queue: {
        readonly __typename?: "SlottedQueue";
      } & SlottedQueueFragment;
    };
  };
};

export type ReassignSlottedQueueEntryMutationVariables = {
  queueEntryId: Scalars["Uuid"];
  newSlotId: Scalars["Uuid"];
};

export type ReassignSlottedQueueEntryMutation = {
  readonly __typename?: "Mutations";
} & {
  readonly reassignSlottedQueueEntry: {
    readonly __typename?: "SlottedQueueTransitionResult";
  } & {
    readonly queue: {
      readonly __typename?: "SlottedQueue";
    } & SlottedQueueFragment;
  };
};

export type ReassignSlottedQueueTicketsMutationVariables = {
  ticketIds: ReadonlyArray<Scalars["Uuid"]>;
  newSlotId: Scalars["Uuid"];
};

export type ReassignSlottedQueueTicketsMutation = {
  readonly __typename?: "Mutations";
} & {
  readonly reassignSlottedQueueTickets: {
    readonly __typename?: "SlottedQueueTransitionResult";
  } & {
    readonly queue: {
      readonly __typename?: "SlottedQueue";
    } & SlottedQueueFragment;
  };
};

export type OpenSlottedQueueSlotMutationVariables = {
  slotId: Scalars["Uuid"];
};

export type OpenSlottedQueueSlotMutation = {
  readonly __typename?: "Mutations";
} & {
  readonly setSlottedQueueSlotMode: {
    readonly __typename?: "SlottedQueueSlotTransitionResult";
  } & {
    readonly slot: { readonly __typename?: "SlottedQueueSlot" } & Pick<
      SlottedQueueSlot,
      "id" | "mode"
    >;
  };
};

export type CancelSlottedQueueSlotMutationVariables = {
  slotId: Scalars["Uuid"];
};

export type CancelSlottedQueueSlotMutation = {
  readonly __typename?: "Mutations";
} & {
  readonly setSlottedQueueSlotMode: {
    readonly __typename?: "SlottedQueueSlotTransitionResult";
  } & {
    readonly slot: { readonly __typename?: "SlottedQueueSlot" } & Pick<
      SlottedQueueSlot,
      "id" | "mode"
    >;
  };
};

export type ReserveSlotSeatsMutationVariables = {
  slotId: Scalars["Uuid"];
  reservedSeats: Scalars["Int"];
  notes: Scalars["String"];
};

export type ReserveSlotSeatsMutation = { readonly __typename?: "Mutations" } & {
  readonly setSlottedQueueSlotAttributes: {
    readonly __typename?: "SlottedQueueSlotTransitionResult";
  } & {
    readonly slot: { readonly __typename?: "SlottedQueueSlot" } & {
      readonly queue: {
        readonly __typename?: "SlottedQueue";
      } & SlottedQueueFragment;
    };
  };
};

export type CancelSlottedQueueMutationVariables = {
  queueId: Scalars["Uuid"];
};

export type CancelSlottedQueueMutation = {
  readonly __typename?: "Mutations";
} & {
  readonly cancelSlottedQueue: {
    readonly __typename?: "SlottedQueueTransitionResult";
  } & {
    readonly queue: {
      readonly __typename?: "SlottedQueue";
    } & SlottedQueueFragment;
  };
};

export type SetSlottedQueueModeMutationVariables = {
  queueId: Scalars["Uuid"];
  mode: SlottedQueueMode;
};

export type SetSlottedQueueModeMutation = {
  readonly __typename?: "Mutations";
} & {
  readonly setSlottedQueueMode: {
    readonly __typename?: "SlottedQueueTransitionResult";
  } & {
    readonly queue: {
      readonly __typename?: "SlottedQueue";
    } & SlottedQueueFragment;
  };
};

export type CancelDynamicQueueMutationVariables = {
  queueId: Scalars["Uuid"];
};

export type CancelDynamicQueueMutation = {
  readonly __typename?: "Mutations";
} & {
  readonly cancelDynamicQueue: {
    readonly __typename?: "DynamicQueueTransitionResult";
  } & {
    readonly queue: {
      readonly __typename?: "DynamicQueue";
    } & DynamicQueueFragment;
  };
};

export type OpenDynamicQueueMutationVariables = {
  queueId: Scalars["Uuid"];
};

export type OpenDynamicQueueMutation = { readonly __typename?: "Mutations" } & {
  readonly openDynamicQueue: {
    readonly __typename?: "DynamicQueueTransitionResult";
  } & {
    readonly queue: {
      readonly __typename?: "DynamicQueue";
    } & DynamicQueueFragment;
  };
};

export type ActiveQueueEntryFragment = {
  readonly __typename?: "DynamicQueueEntry";
} & Pick<
  DynamicQueueEntry,
  "id" | "shortCode" | "state" | "stateChangedAt" | "summonedEstimate"
> & {
    readonly session: { readonly __typename?: "Session" } & Pick<Session, "id">;
  };

export type DynamicQueueDetailQueryVariables = {
  id: Scalars["Uuid"];
};

export type DynamicQueueDetailQuery = { readonly __typename?: "Query" } & Pick<
  Query,
  "now"
> & {
    readonly queue: Maybe<
      { readonly __typename?: "DynamicQueue" } & DynamicQueueFragment
    >;
    readonly viewer: Maybe<
      { readonly __typename?: "UserSession" } & Pick<UserSession, "sessionId">
    >;
  };

export type DynamicQueueEntryFragment = {
  readonly __typename?: "DynamicQueueEntry";
} & Pick<
  DynamicQueueEntry,
  | "id"
  | "shortCode"
  | "state"
  | "numberOfPeople"
  | "stateChangedAt"
  | "estimatedScore"
> & {
    readonly session: { readonly __typename?: "Session" } & Pick<
      Session,
      | "id"
      | "isBusy"
      | "metadataOsVersion"
      | "metadataAppVersion"
      | "metadataDeviceRole"
    > & {
        readonly latestSessionInfo: Maybe<
          { readonly __typename?: "SessionInfo" } & Pick<
            SessionInfo,
            "createdAt" | "batteryLevel"
          > & {
              readonly locateSpaces: ReadonlyArray<
                { readonly __typename?: "LocateSpace" } & Pick<
                  LocateSpace,
                  "id" | "name"
                >
              >;
            }
        >;
      };
  };

export type DynamicQueueFragment = {
  readonly __typename?: "DynamicQueue";
} & Pick<
  DynamicQueue,
  | "id"
  | "name"
  | "shortName"
  | "closeTime"
  | "state"
  | "isFull"
  | "summonedEstimate"
> & {
    readonly lobbyRegions: Maybe<
      ReadonlyArray<
        { readonly __typename?: "SpaceRegion" } & Pick<
          SpaceRegion,
          "ensoSpaceId"
        >
      >
    >;
    readonly nearbyRegions: Maybe<
      ReadonlyArray<
        { readonly __typename?: "SpaceRegion" } & Pick<
          SpaceRegion,
          "ensoSpaceId"
        >
      >
    >;
    readonly todaysQueueEntries: Maybe<
      ReadonlyArray<
        {
          readonly __typename?: "DynamicQueueEntry";
        } & DynamicQueueEntryFragment
      >
    >;
  };

export type QueueListQueryVariables = {};

export type QueueListQuery = { readonly __typename?: "Query" } & {
  readonly dynamicQueues: ReadonlyArray<
    { readonly __typename?: "DynamicQueue" } & Pick<
      DynamicQueue,
      "shortName" | "id"
    >
  >;
  readonly slottedQueues: ReadonlyArray<
    { readonly __typename?: "SlottedQueue" } & Pick<
      SlottedQueue,
      "shortName" | "id" | "mode"
    >
  >;
  readonly viewer: Maybe<
    { readonly __typename?: "UserSession" } & Pick<UserSession, "sessionId">
  >;
};

export type SlottedQueueDetailQueryVariables = {
  id: Scalars["Uuid"];
};

export type SlottedQueueDetailQuery = { readonly __typename?: "Query" } & Pick<
  Query,
  "now"
> & {
    readonly queue: Maybe<
      { readonly __typename?: "SlottedQueue" } & SlottedQueueFragment
    >;
    readonly viewer: Maybe<
      { readonly __typename?: "UserSession" } & Pick<UserSession, "sessionId">
    >;
  };

export type SlottedQueueTicketInfoFragment = {
  readonly __typename?: "TessituraTicket";
} & Pick<TessituraTicket, "id" | "ticketNumber"> & {
    readonly order: { readonly __typename?: "TessituraOrder" } & Pick<
      TessituraOrder,
      "id" | "constituentEmail"
    >;
    readonly slottedQueueEntry: Maybe<
      { readonly __typename?: "SlottedQueueEntry" } & Pick<
        SlottedQueueEntry,
        "id"
      >
    >;
  };

export type SlottedQueueEntryFragment = {
  readonly __typename?: "SlottedQueueEntry";
} & Pick<
  SlottedQueueEntry,
  "id" | "createdAt" | "shortCode" | "numberOfPeople" | "shortCode" | "state"
> & {
    readonly tickets: ReadonlyArray<
      {
        readonly __typename?: "TessituraTicket";
      } & SlottedQueueTicketInfoFragment
    >;
    readonly session: { readonly __typename?: "Session" } & Pick<
      Session,
      | "id"
      | "isBusy"
      | "metadataOsVersion"
      | "metadataAppVersion"
      | "metadataDeviceRole"
    > & {
        readonly latestSessionInfo: Maybe<
          { readonly __typename?: "SessionInfo" } & Pick<
            SessionInfo,
            "createdAt" | "batteryLevel"
          > & {
              readonly locateSpaces: ReadonlyArray<
                { readonly __typename?: "LocateSpace" } & Pick<
                  LocateSpace,
                  "id" | "name"
                >
              >;
            }
        >;
        readonly activeDynamicQueueEntries: ReadonlyArray<
          { readonly __typename?: "DynamicQueueEntry" } & Pick<
            DynamicQueueEntry,
            "id" | "createdAt" | "state"
          > & {
              readonly queue: { readonly __typename?: "DynamicQueue" } & Pick<
                DynamicQueue,
                "id" | "name"
              >;
            }
        >;
        readonly activeSlottedQueueEntries: ReadonlyArray<
          { readonly __typename?: "SlottedQueueEntry" } & Pick<
            SlottedQueueEntry,
            "id" | "createdAt" | "state"
          > & {
              readonly queue: { readonly __typename?: "SlottedQueue" } & Pick<
                SlottedQueue,
                "id" | "name"
              >;
            }
        >;
      };
  };

export type SlottedQueueFragment = {
  readonly __typename?: "SlottedQueue";
} & Pick<
  SlottedQueue,
  | "id"
  | "name"
  | "shortName"
  | "mode"
  | "title"
  | "exhibitCapacityPeople"
  | "closeTimeToday"
  | "scheduledLastSlotStartTime"
  | "scheduledLastSlotStartTimeToday"
  | "slotIntervalSeconds"
  | "servedTodayCount"
  | "totalTicketsTodayCount"
  | "claimedUnservedTicketsTodayCount"
  | "remainingUnclaimedTicketsTodayCount"
  | "remainingSeatsNext2HoursCount"
  | "seatsRemainingTodayCount"
> & {
    readonly nearbyRegions: Maybe<
      ReadonlyArray<
        { readonly __typename?: "SpaceRegion" } & Pick<
          SpaceRegion,
          "ensoSpaceId"
        >
      >
    >;
    readonly todaysUnallocatedQueueEntries: ReadonlyArray<
      { readonly __typename?: "SlottedQueueEntry" } & SlottedQueueEntryFragment
    >;
    readonly todaysSlots: ReadonlyArray<
      { readonly __typename?: "SlottedQueueSlot" } & Pick<
        SlottedQueueSlot,
        | "id"
        | "startsAt"
        | "mode"
        | "startsAfterQueueCloseTime"
        | "withinAllocationWindow"
        | "notes"
        | "reservedSeats"
        | "maxReservableSeats"
        | "availableSeatCount"
      > & {
          readonly queueEntries: ReadonlyArray<
            {
              readonly __typename?: "SlottedQueueEntry";
            } & SlottedQueueEntryFragment
          >;
          readonly tickets: ReadonlyArray<
            {
              readonly __typename?: "TessituraTicket";
            } & SlottedQueueTicketInfoFragment
          >;
          readonly unacknowledgedReassignedTickets: ReadonlyArray<
            { readonly __typename?: "UnacknowledgedReassignedTicket" } & {
              readonly ticket: {
                readonly __typename?: "TessituraTicket";
              } & SlottedQueueTicketInfoFragment;
              readonly newSlot: {
                readonly __typename?: "SlottedQueueSlot";
              } & Pick<SlottedQueueSlot, "startsAt">;
            }
          >;
          readonly offeredJumps: ReadonlyArray<
            { readonly __typename?: "SlottedQueueJump" } & Pick<
              SlottedQueueJump,
              "id" | "state" | "offeredAt" | "resolvedAt"
            > & {
                readonly queueEntry: {
                  readonly __typename?: "SlottedQueueEntry";
                } & SlottedQueueEntryFragment;
              }
          >;
        }
    >;
  };

export type UserSessionQueryVariables = {};

export type UserSessionQuery = { readonly __typename?: "Query" } & {
  readonly viewer: Maybe<
    { readonly __typename?: "UserSession" } & Pick<UserSession, "sessionId">
  >;
};
export const activeQueueEntryFragmentDoc = gql`
  fragment activeQueueEntry on DynamicQueueEntry {
    id
    shortCode
    state
    stateChangedAt
    summonedEstimate
    session {
      id
    }
  }
`;
export const dynamicQueueEntryFragmentDoc = gql`
  fragment dynamicQueueEntry on DynamicQueueEntry {
    id
    shortCode
    state
    numberOfPeople
    stateChangedAt
    estimatedScore
    session {
      id
      isBusy
      metadataOsVersion
      metadataAppVersion
      metadataDeviceRole
      latestSessionInfo {
        createdAt
        batteryLevel
        locateSpaces {
          id
          name
        }
      }
    }
  }
`;
export const dynamicQueueFragmentDoc = gql`
  fragment dynamicQueue on DynamicQueue {
    id
    name
    shortName
    closeTime
    state
    isFull
    lobbyRegions {
      ... on SpaceRegion {
        ensoSpaceId
      }
    }
    nearbyRegions {
      ... on SpaceRegion {
        ensoSpaceId
      }
    }
    summonedEstimate
    todaysQueueEntries {
      ...dynamicQueueEntry
    }
  }
  ${dynamicQueueEntryFragmentDoc}
`;
export const slottedQueueTicketInfoFragmentDoc = gql`
  fragment slottedQueueTicketInfo on TessituraTicket {
    id
    ticketNumber
    order {
      id
      constituentEmail
    }
    slottedQueueEntry {
      id
    }
  }
`;
export const slottedQueueEntryFragmentDoc = gql`
  fragment slottedQueueEntry on SlottedQueueEntry {
    id
    createdAt
    shortCode
    numberOfPeople
    shortCode
    state
    tickets {
      ...slottedQueueTicketInfo
    }
    session {
      id
      isBusy
      metadataOsVersion
      metadataAppVersion
      metadataDeviceRole
      latestSessionInfo {
        createdAt
        batteryLevel
        locateSpaces {
          id
          name
        }
      }
      activeDynamicQueueEntries {
        id
        createdAt
        state
        queue {
          id
          name
        }
      }
      activeSlottedQueueEntries {
        id
        createdAt
        state
        queue {
          id
          name
        }
      }
    }
  }
  ${slottedQueueTicketInfoFragmentDoc}
`;
export const slottedQueueFragmentDoc = gql`
  fragment slottedQueue on SlottedQueue {
    id
    name
    shortName
    mode
    title
    exhibitCapacityPeople
    closeTimeToday
    scheduledLastSlotStartTime
    scheduledLastSlotStartTimeToday
    slotIntervalSeconds
    servedTodayCount
    totalTicketsTodayCount
    claimedUnservedTicketsTodayCount
    remainingUnclaimedTicketsTodayCount
    remainingSeatsNext2HoursCount
    seatsRemainingTodayCount
    nearbyRegions {
      ... on SpaceRegion {
        ensoSpaceId
      }
    }
    todaysUnallocatedQueueEntries {
      ...slottedQueueEntry
    }
    todaysSlots {
      id
      startsAt
      mode
      startsAfterQueueCloseTime
      withinAllocationWindow
      notes
      reservedSeats
      maxReservableSeats
      availableSeatCount
      queueEntries {
        ...slottedQueueEntry
      }
      tickets {
        ...slottedQueueTicketInfo
      }
      unacknowledgedReassignedTickets {
        ticket {
          ...slottedQueueTicketInfo
        }
        newSlot {
          startsAt
        }
      }
      offeredJumps {
        id
        state
        offeredAt
        resolvedAt
        queueEntry {
          ...slottedQueueEntry
        }
      }
    }
  }
  ${slottedQueueEntryFragmentDoc}
  ${slottedQueueTicketInfoFragmentDoc}
`;
export const ChangeDynamicQueueCloseTimeDocument = gql`
  mutation changeDynamicQueueCloseTime(
    $queueId: Uuid!
    $closeTime: NaiveTime!
  ) {
    changeDynamicQueueCloseTime(
      input: { queueId: $queueId, closeTime: $closeTime }
    ) {
      queue {
        ...dynamicQueue
      }
    }
  }
  ${dynamicQueueFragmentDoc}
`;
export type ChangeDynamicQueueCloseTimeMutationFn = ReactApollo.MutationFn<
  ChangeDynamicQueueCloseTimeMutation,
  ChangeDynamicQueueCloseTimeMutationVariables
>;
export type ChangeDynamicQueueCloseTimeProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ChangeDynamicQueueCloseTimeMutation,
    ChangeDynamicQueueCloseTimeMutationVariables
  >
> &
  TChildProps;
export function withChangeDynamicQueueCloseTime<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ChangeDynamicQueueCloseTimeMutation,
    ChangeDynamicQueueCloseTimeMutationVariables,
    ChangeDynamicQueueCloseTimeProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ChangeDynamicQueueCloseTimeMutation,
    ChangeDynamicQueueCloseTimeMutationVariables,
    ChangeDynamicQueueCloseTimeProps<TChildProps>
  >(ChangeDynamicQueueCloseTimeDocument, {
    alias: "withChangeDynamicQueueCloseTime",
    ...operationOptions
  });
}
export const ChangeSlottedQueueScheduledLastSlotStartTimeDocument = gql`
  mutation changeSlottedQueueScheduledLastSlotStartTime(
    $queueId: Uuid!
    $closeTime: NaiveTime!
  ) {
    changeSlottedQueueScheduledLastSlotStartTime(
      input: { queueId: $queueId, startTime: $closeTime }
    ) {
      queue {
        ...slottedQueue
      }
    }
  }
  ${slottedQueueFragmentDoc}
`;
export type ChangeSlottedQueueScheduledLastSlotStartTimeMutationFn = ReactApollo.MutationFn<
  ChangeSlottedQueueScheduledLastSlotStartTimeMutation,
  ChangeSlottedQueueScheduledLastSlotStartTimeMutationVariables
>;
export type ChangeSlottedQueueScheduledLastSlotStartTimeProps<
  TChildProps = {}
> = Partial<
  ReactApollo.MutateProps<
    ChangeSlottedQueueScheduledLastSlotStartTimeMutation,
    ChangeSlottedQueueScheduledLastSlotStartTimeMutationVariables
  >
> &
  TChildProps;
export function withChangeSlottedQueueScheduledLastSlotStartTime<
  TProps,
  TChildProps = {}
>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ChangeSlottedQueueScheduledLastSlotStartTimeMutation,
    ChangeSlottedQueueScheduledLastSlotStartTimeMutationVariables,
    ChangeSlottedQueueScheduledLastSlotStartTimeProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ChangeSlottedQueueScheduledLastSlotStartTimeMutation,
    ChangeSlottedQueueScheduledLastSlotStartTimeMutationVariables,
    ChangeSlottedQueueScheduledLastSlotStartTimeProps<TChildProps>
  >(ChangeSlottedQueueScheduledLastSlotStartTimeDocument, {
    alias: "withChangeSlottedQueueScheduledLastSlotStartTime",
    ...operationOptions
  });
}
export const ForceSummonDynamicQueueEntryDocument = gql`
  mutation forceSummonDynamicQueueEntry($queueEntryId: Uuid!) {
    forceSummonDynamicQueueEntry(input: { queueEntryId: $queueEntryId }) {
      queueEntry {
        id
        state
        stateChangedAt
      }
    }
  }
`;
export type ForceSummonDynamicQueueEntryMutationFn = ReactApollo.MutationFn<
  ForceSummonDynamicQueueEntryMutation,
  ForceSummonDynamicQueueEntryMutationVariables
>;
export type ForceSummonDynamicQueueEntryProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ForceSummonDynamicQueueEntryMutation,
    ForceSummonDynamicQueueEntryMutationVariables
  >
> &
  TChildProps;
export function withForceSummonDynamicQueueEntry<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ForceSummonDynamicQueueEntryMutation,
    ForceSummonDynamicQueueEntryMutationVariables,
    ForceSummonDynamicQueueEntryProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ForceSummonDynamicQueueEntryMutation,
    ForceSummonDynamicQueueEntryMutationVariables,
    ForceSummonDynamicQueueEntryProps<TChildProps>
  >(ForceSummonDynamicQueueEntryDocument, {
    alias: "withForceSummonDynamicQueueEntry",
    ...operationOptions
  });
}
export const ServeDynamicQueueEntryDocument = gql`
  mutation serveDynamicQueueEntry($queueEntryId: Uuid!) {
    serveDynamicQueueEntry(input: { queueEntryId: $queueEntryId }) {
      queueEntry {
        id
        state
        stateChangedAt
      }
    }
  }
`;
export type ServeDynamicQueueEntryMutationFn = ReactApollo.MutationFn<
  ServeDynamicQueueEntryMutation,
  ServeDynamicQueueEntryMutationVariables
>;
export type ServeDynamicQueueEntryProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ServeDynamicQueueEntryMutation,
    ServeDynamicQueueEntryMutationVariables
  >
> &
  TChildProps;
export function withServeDynamicQueueEntry<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ServeDynamicQueueEntryMutation,
    ServeDynamicQueueEntryMutationVariables,
    ServeDynamicQueueEntryProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ServeDynamicQueueEntryMutation,
    ServeDynamicQueueEntryMutationVariables,
    ServeDynamicQueueEntryProps<TChildProps>
  >(ServeDynamicQueueEntryDocument, {
    alias: "withServeDynamicQueueEntry",
    ...operationOptions
  });
}
export const ForceSummonNextDynamicQueueEntryDocument = gql`
  mutation forceSummonNextDynamicQueueEntry($queueId: Uuid!) {
    forceSummonNextDynamicQueueEntry(input: { queueId: $queueId }) {
      queueEntry {
        ...activeQueueEntry
      }
    }
  }
  ${activeQueueEntryFragmentDoc}
`;
export type ForceSummonNextDynamicQueueEntryMutationFn = ReactApollo.MutationFn<
  ForceSummonNextDynamicQueueEntryMutation,
  ForceSummonNextDynamicQueueEntryMutationVariables
>;
export type ForceSummonNextDynamicQueueEntryProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ForceSummonNextDynamicQueueEntryMutation,
    ForceSummonNextDynamicQueueEntryMutationVariables
  >
> &
  TChildProps;
export function withForceSummonNextDynamicQueueEntry<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ForceSummonNextDynamicQueueEntryMutation,
    ForceSummonNextDynamicQueueEntryMutationVariables,
    ForceSummonNextDynamicQueueEntryProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ForceSummonNextDynamicQueueEntryMutation,
    ForceSummonNextDynamicQueueEntryMutationVariables,
    ForceSummonNextDynamicQueueEntryProps<TChildProps>
  >(ForceSummonNextDynamicQueueEntryDocument, {
    alias: "withForceSummonNextDynamicQueueEntry",
    ...operationOptions
  });
}
export const SetSlotModeDocument = gql`
  mutation setSlotMode($slotId: Uuid!, $mode: SlotMode!) {
    setSlottedQueueSlotMode(input: { slotId: $slotId, mode: $mode }) {
      slot {
        queue {
          ...slottedQueue
        }
      }
    }
  }
  ${slottedQueueFragmentDoc}
`;
export type SetSlotModeMutationFn = ReactApollo.MutationFn<
  SetSlotModeMutation,
  SetSlotModeMutationVariables
>;
export type SetSlotModeProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<SetSlotModeMutation, SetSlotModeMutationVariables>
> &
  TChildProps;
export function withSetSlotMode<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SetSlotModeMutation,
    SetSlotModeMutationVariables,
    SetSlotModeProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    SetSlotModeMutation,
    SetSlotModeMutationVariables,
    SetSlotModeProps<TChildProps>
  >(SetSlotModeDocument, {
    alias: "withSetSlotMode",
    ...operationOptions
  });
}
export const ReassignSlottedQueueEntryDocument = gql`
  mutation reassignSlottedQueueEntry($queueEntryId: Uuid!, $newSlotId: Uuid!) {
    reassignSlottedQueueEntry(
      input: { queueEntryId: $queueEntryId, newSlotId: $newSlotId }
    ) {
      queue {
        ...slottedQueue
      }
    }
  }
  ${slottedQueueFragmentDoc}
`;
export type ReassignSlottedQueueEntryMutationFn = ReactApollo.MutationFn<
  ReassignSlottedQueueEntryMutation,
  ReassignSlottedQueueEntryMutationVariables
>;
export type ReassignSlottedQueueEntryProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ReassignSlottedQueueEntryMutation,
    ReassignSlottedQueueEntryMutationVariables
  >
> &
  TChildProps;
export function withReassignSlottedQueueEntry<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ReassignSlottedQueueEntryMutation,
    ReassignSlottedQueueEntryMutationVariables,
    ReassignSlottedQueueEntryProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ReassignSlottedQueueEntryMutation,
    ReassignSlottedQueueEntryMutationVariables,
    ReassignSlottedQueueEntryProps<TChildProps>
  >(ReassignSlottedQueueEntryDocument, {
    alias: "withReassignSlottedQueueEntry",
    ...operationOptions
  });
}
export const ReassignSlottedQueueTicketsDocument = gql`
  mutation reassignSlottedQueueTickets(
    $ticketIds: [Uuid!]!
    $newSlotId: Uuid!
  ) {
    reassignSlottedQueueTickets(
      input: { ticketIds: $ticketIds, newSlotId: $newSlotId }
    ) {
      queue {
        ...slottedQueue
      }
    }
  }
  ${slottedQueueFragmentDoc}
`;
export type ReassignSlottedQueueTicketsMutationFn = ReactApollo.MutationFn<
  ReassignSlottedQueueTicketsMutation,
  ReassignSlottedQueueTicketsMutationVariables
>;
export type ReassignSlottedQueueTicketsProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ReassignSlottedQueueTicketsMutation,
    ReassignSlottedQueueTicketsMutationVariables
  >
> &
  TChildProps;
export function withReassignSlottedQueueTickets<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ReassignSlottedQueueTicketsMutation,
    ReassignSlottedQueueTicketsMutationVariables,
    ReassignSlottedQueueTicketsProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ReassignSlottedQueueTicketsMutation,
    ReassignSlottedQueueTicketsMutationVariables,
    ReassignSlottedQueueTicketsProps<TChildProps>
  >(ReassignSlottedQueueTicketsDocument, {
    alias: "withReassignSlottedQueueTickets",
    ...operationOptions
  });
}
export const OpenSlottedQueueSlotDocument = gql`
  mutation openSlottedQueueSlot($slotId: Uuid!) {
    setSlottedQueueSlotMode(input: { slotId: $slotId, mode: DEFAULT }) {
      slot {
        id
        mode
      }
    }
  }
`;
export type OpenSlottedQueueSlotMutationFn = ReactApollo.MutationFn<
  OpenSlottedQueueSlotMutation,
  OpenSlottedQueueSlotMutationVariables
>;
export type OpenSlottedQueueSlotProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    OpenSlottedQueueSlotMutation,
    OpenSlottedQueueSlotMutationVariables
  >
> &
  TChildProps;
export function withOpenSlottedQueueSlot<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    OpenSlottedQueueSlotMutation,
    OpenSlottedQueueSlotMutationVariables,
    OpenSlottedQueueSlotProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    OpenSlottedQueueSlotMutation,
    OpenSlottedQueueSlotMutationVariables,
    OpenSlottedQueueSlotProps<TChildProps>
  >(OpenSlottedQueueSlotDocument, {
    alias: "withOpenSlottedQueueSlot",
    ...operationOptions
  });
}
export const CancelSlottedQueueSlotDocument = gql`
  mutation cancelSlottedQueueSlot($slotId: Uuid!) {
    setSlottedQueueSlotMode(input: { slotId: $slotId, mode: CANCELLED }) {
      slot {
        id
        mode
      }
    }
  }
`;
export type CancelSlottedQueueSlotMutationFn = ReactApollo.MutationFn<
  CancelSlottedQueueSlotMutation,
  CancelSlottedQueueSlotMutationVariables
>;
export type CancelSlottedQueueSlotProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    CancelSlottedQueueSlotMutation,
    CancelSlottedQueueSlotMutationVariables
  >
> &
  TChildProps;
export function withCancelSlottedQueueSlot<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CancelSlottedQueueSlotMutation,
    CancelSlottedQueueSlotMutationVariables,
    CancelSlottedQueueSlotProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    CancelSlottedQueueSlotMutation,
    CancelSlottedQueueSlotMutationVariables,
    CancelSlottedQueueSlotProps<TChildProps>
  >(CancelSlottedQueueSlotDocument, {
    alias: "withCancelSlottedQueueSlot",
    ...operationOptions
  });
}
export const ReserveSlotSeatsDocument = gql`
  mutation reserveSlotSeats(
    $slotId: Uuid!
    $reservedSeats: Int!
    $notes: String!
  ) {
    setSlottedQueueSlotAttributes(
      input: { slotId: $slotId, reservedSeats: $reservedSeats, notes: $notes }
    ) {
      slot {
        queue {
          ...slottedQueue
        }
      }
    }
  }
  ${slottedQueueFragmentDoc}
`;
export type ReserveSlotSeatsMutationFn = ReactApollo.MutationFn<
  ReserveSlotSeatsMutation,
  ReserveSlotSeatsMutationVariables
>;
export type ReserveSlotSeatsProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ReserveSlotSeatsMutation,
    ReserveSlotSeatsMutationVariables
  >
> &
  TChildProps;
export function withReserveSlotSeats<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ReserveSlotSeatsMutation,
    ReserveSlotSeatsMutationVariables,
    ReserveSlotSeatsProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ReserveSlotSeatsMutation,
    ReserveSlotSeatsMutationVariables,
    ReserveSlotSeatsProps<TChildProps>
  >(ReserveSlotSeatsDocument, {
    alias: "withReserveSlotSeats",
    ...operationOptions
  });
}
export const CancelSlottedQueueDocument = gql`
  mutation cancelSlottedQueue($queueId: Uuid!) {
    cancelSlottedQueue(input: { queueId: $queueId }) {
      queue {
        ...slottedQueue
      }
    }
  }
  ${slottedQueueFragmentDoc}
`;
export type CancelSlottedQueueMutationFn = ReactApollo.MutationFn<
  CancelSlottedQueueMutation,
  CancelSlottedQueueMutationVariables
>;
export type CancelSlottedQueueProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    CancelSlottedQueueMutation,
    CancelSlottedQueueMutationVariables
  >
> &
  TChildProps;
export function withCancelSlottedQueue<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CancelSlottedQueueMutation,
    CancelSlottedQueueMutationVariables,
    CancelSlottedQueueProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    CancelSlottedQueueMutation,
    CancelSlottedQueueMutationVariables,
    CancelSlottedQueueProps<TChildProps>
  >(CancelSlottedQueueDocument, {
    alias: "withCancelSlottedQueue",
    ...operationOptions
  });
}
export const SetSlottedQueueModeDocument = gql`
  mutation setSlottedQueueMode($queueId: Uuid!, $mode: SlottedQueueMode!) {
    setSlottedQueueMode(input: { queueId: $queueId, mode: $mode }) {
      queue {
        ...slottedQueue
      }
    }
  }
  ${slottedQueueFragmentDoc}
`;
export type SetSlottedQueueModeMutationFn = ReactApollo.MutationFn<
  SetSlottedQueueModeMutation,
  SetSlottedQueueModeMutationVariables
>;
export type SetSlottedQueueModeProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    SetSlottedQueueModeMutation,
    SetSlottedQueueModeMutationVariables
  >
> &
  TChildProps;
export function withSetSlottedQueueMode<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SetSlottedQueueModeMutation,
    SetSlottedQueueModeMutationVariables,
    SetSlottedQueueModeProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    SetSlottedQueueModeMutation,
    SetSlottedQueueModeMutationVariables,
    SetSlottedQueueModeProps<TChildProps>
  >(SetSlottedQueueModeDocument, {
    alias: "withSetSlottedQueueMode",
    ...operationOptions
  });
}
export const CancelDynamicQueueDocument = gql`
  mutation cancelDynamicQueue($queueId: Uuid!) {
    cancelDynamicQueue(input: { queueId: $queueId }) {
      queue {
        ...dynamicQueue
      }
    }
  }
  ${dynamicQueueFragmentDoc}
`;
export type CancelDynamicQueueMutationFn = ReactApollo.MutationFn<
  CancelDynamicQueueMutation,
  CancelDynamicQueueMutationVariables
>;
export type CancelDynamicQueueProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    CancelDynamicQueueMutation,
    CancelDynamicQueueMutationVariables
  >
> &
  TChildProps;
export function withCancelDynamicQueue<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CancelDynamicQueueMutation,
    CancelDynamicQueueMutationVariables,
    CancelDynamicQueueProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    CancelDynamicQueueMutation,
    CancelDynamicQueueMutationVariables,
    CancelDynamicQueueProps<TChildProps>
  >(CancelDynamicQueueDocument, {
    alias: "withCancelDynamicQueue",
    ...operationOptions
  });
}
export const OpenDynamicQueueDocument = gql`
  mutation openDynamicQueue($queueId: Uuid!) {
    openDynamicQueue(input: { queueId: $queueId }) {
      queue {
        ...dynamicQueue
      }
    }
  }
  ${dynamicQueueFragmentDoc}
`;
export type OpenDynamicQueueMutationFn = ReactApollo.MutationFn<
  OpenDynamicQueueMutation,
  OpenDynamicQueueMutationVariables
>;
export type OpenDynamicQueueProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    OpenDynamicQueueMutation,
    OpenDynamicQueueMutationVariables
  >
> &
  TChildProps;
export function withOpenDynamicQueue<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    OpenDynamicQueueMutation,
    OpenDynamicQueueMutationVariables,
    OpenDynamicQueueProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    OpenDynamicQueueMutation,
    OpenDynamicQueueMutationVariables,
    OpenDynamicQueueProps<TChildProps>
  >(OpenDynamicQueueDocument, {
    alias: "withOpenDynamicQueue",
    ...operationOptions
  });
}
export const DynamicQueueDetailDocument = gql`
  query dynamicQueueDetail($id: Uuid!) {
    queue: dynamicQueue(id: $id) {
      ...dynamicQueue
    }
    viewer {
      sessionId
    }
    now
  }
  ${dynamicQueueFragmentDoc}
`;
export type DynamicQueueDetailProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    DynamicQueueDetailQuery,
    DynamicQueueDetailQueryVariables
  >
> &
  TChildProps;
export function withDynamicQueueDetail<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    DynamicQueueDetailQuery,
    DynamicQueueDetailQueryVariables,
    DynamicQueueDetailProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    DynamicQueueDetailQuery,
    DynamicQueueDetailQueryVariables,
    DynamicQueueDetailProps<TChildProps>
  >(DynamicQueueDetailDocument, {
    alias: "withDynamicQueueDetail",
    ...operationOptions
  });
}
export const QueueListDocument = gql`
  query queueList {
    dynamicQueues {
      shortName
      id
    }
    slottedQueues {
      shortName
      id
      mode
    }
    viewer {
      sessionId
    }
  }
`;
export type QueueListProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<QueueListQuery, QueueListQueryVariables>
> &
  TChildProps;
export function withQueueList<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    QueueListQuery,
    QueueListQueryVariables,
    QueueListProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    QueueListQuery,
    QueueListQueryVariables,
    QueueListProps<TChildProps>
  >(QueueListDocument, {
    alias: "withQueueList",
    ...operationOptions
  });
}
export const SlottedQueueDetailDocument = gql`
  query slottedQueueDetail($id: Uuid!) {
    queue: slottedQueue(id: $id) {
      ...slottedQueue
    }
    viewer {
      sessionId
    }
    now
  }
  ${slottedQueueFragmentDoc}
`;
export type SlottedQueueDetailProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    SlottedQueueDetailQuery,
    SlottedQueueDetailQueryVariables
  >
> &
  TChildProps;
export function withSlottedQueueDetail<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SlottedQueueDetailQuery,
    SlottedQueueDetailQueryVariables,
    SlottedQueueDetailProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    SlottedQueueDetailQuery,
    SlottedQueueDetailQueryVariables,
    SlottedQueueDetailProps<TChildProps>
  >(SlottedQueueDetailDocument, {
    alias: "withSlottedQueueDetail",
    ...operationOptions
  });
}
export const UserSessionDocument = gql`
  query userSession {
    viewer {
      sessionId
    }
  }
`;
export type UserSessionProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<UserSessionQuery, UserSessionQueryVariables>
> &
  TChildProps;
export function withUserSession<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UserSessionQuery,
    UserSessionQueryVariables,
    UserSessionProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    UserSessionQuery,
    UserSessionQueryVariables,
    UserSessionProps<TChildProps>
  >(UserSessionDocument, {
    alias: "withUserSession",
    ...operationOptions
  });
}
