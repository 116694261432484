import React from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { Button, Modal } from "react-bootstrap";

import slottedQueueFragment from "../../queries/slottedQueueFragment";
import { ReserveSlotSeatsMutationFn } from "generated/graphql";

type OwnProps = {
  slotId: string;
  title: string;
  notes: string;
  reservedSeats: number;
  maxReservableSeats: number;
};

type Props = OwnProps & { mutate: ReserveSlotSeatsMutationFn };

type State = {
  reservedSeats: number;
  showModal: boolean;
  notes: string;
};

class ReserveSeatsButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
      reservedSeats: props.reservedSeats,
      notes: props.notes,
    };
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (this.props.reservedSeats !== nextProps.reservedSeats) {
      this.setState({ reservedSeats: nextProps.reservedSeats });
    }

    if (this.props.notes !== nextProps.notes) {
      this.setState({ notes: nextProps.notes });
    }
  }

  private openModal = () => this.setState({ showModal: true });

  private closeModal = () => this.setState({ showModal: false });

  private onSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    this.props.mutate({
      variables: {
        slotId: this.props.slotId,
        reservedSeats: this.state.reservedSeats,
        notes: this.state.notes,
      },
    });

    this.setState({ showModal: false });

    ev.preventDefault();
  };

  private onReservationChange = (ev: React.SyntheticEvent<HTMLSelectElement>) => {
    let target = ev.target;
    let reservedSeats = target instanceof HTMLSelectElement ? parseInt(target.value) : 0;
    this.setState({ showModal: this.state.showModal, reservedSeats: reservedSeats });
  }

  private onNotesUpdate = (ev: React.SyntheticEvent<HTMLTextAreaElement>) => {
    let target = ev.target;
    let notes = target instanceof HTMLTextAreaElement ? target.value : "";
    this.setState({ showModal: this.state.showModal, notes: notes });
  }

  public render = () => {
    let seatOptions = [];

    for (let i = 0; i <= this.props.maxReservableSeats; i++) {
      seatOptions.push(i);
    }

    return (
      <>
        <Button onClick={this.openModal}>Set notes and seat reservations</Button>
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <form className="form" onSubmit={this.onSubmit}>
            <Modal.Header>
              <Modal.Title>{this.props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label>Reserved seats</label>
                <select className="form-control" onChange={this.onReservationChange}>
                  {seatOptions.map((count) => (<option selected={count === this.state.reservedSeats}>{count}</option>))}
                </select>
              </div>

              <div className="form-group">
                <label>Slot notes</label>
                <textarea
                  className="form-control"
                  onKeyDown={this.onNotesUpdate}
                  onChange={this.onNotesUpdate}
                  value={this.state.notes}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.closeModal}>Cancel</Button>
              <Button bsStyle="dark" type="submit">Submit</Button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    )
  }
}

const reserveSlotSeatsMutation = gql`
  mutation reserveSlotSeats($slotId: Uuid!, $reservedSeats: Int!, $notes: String!) {
    setSlottedQueueSlotAttributes(input: { slotId: $slotId, reservedSeats: $reservedSeats, notes: $notes }) {
      slot {
        queue {
          ...slottedQueue
        }
      }
    }
  }

  ${slottedQueueFragment}
`;

export default graphql<OwnProps, any, any, Props>(reserveSlotSeatsMutation)(ReserveSeatsButton);

