import React from "react";
import { QueryResult } from "react-apollo";

import { ApiError } from "../ApiError";

type Props = {
  children: any;
  loading: boolean;
  error: QueryResult["error"];
};

export const LoadingGuard = ({ children, loading, error }: Props) => {
  if (loading) {
    return null;
  }
  if (error) {
    console.log('Apollo error', error);
  }

  return (
    <>
      {error && <ApiError />}
      {children}
    </>
  );
};

