import React from "react";
import gql from "graphql-tag";
import { get, has } from "lodash";
import moment from "moment/moment";
import { compose, graphql } from "react-apollo";
import { Button, Modal, Row, Col } from "react-bootstrap";

import * as consts from "../Queues/consts";
import { DynamicQueueEntryFragment } from 'generated/graphql';

import "./DynamicQueueEntryActionModal.css";

const forceSummonMutation = gql`
  mutation forceSummonDynamicQueueEntry($queueEntryId: Uuid!) {
    forceSummonDynamicQueueEntry(input: { queueEntryId: $queueEntryId }) {
      queueEntry {
        id
        state
        stateChangedAt
      }
    }
  }
`;

const serveMutation = gql`
  mutation serveDynamicQueueEntry($queueEntryId: Uuid!) {
    serveDynamicQueueEntry(input: { queueEntryId: $queueEntryId }) {
      queueEntry {
        id
        state
        stateChangedAt
      }
    }
  }
`;

type OwnProps = {
  queueEntry: DynamicQueueEntryFragment;
  onHide: () => void;
  show: boolean;
};

type MagicProps = {
  forceSummonMutation: (x: any) => Promise<any>;
  serveMutation: (x: any) => Promise<any>;
};

type Props = OwnProps & MagicProps;

class DynamicQueueEntryActionModalView extends React.Component<Props, {}> {
  private onForceSummonClick = () => {
    this.props
      .forceSummonMutation({
        variables: { queueEntryId: this.props.queueEntry.id }
      })
      .then(() => this.props.onHide());
  };

  private onServeClick = () => {
    this.props
      .serveMutation({
        variables: { queueEntryId: this.props.queueEntry.id }
      })
      .then(() => this.props.onHide());
  };

  public render() {
    const { show, onHide = () => {}, queueEntry } = this.props;

    let isSessionResponsive = true;
    if (has(queueEntry, "session.latestSessionInfo.createdAt")) {
      const lastResponsiveAt = moment(
        get(queueEntry, "session.latestSessionInfo.createdAt")
      );
      if (lastResponsiveAt.isBefore(moment().subtract(5, "minutes"))) {
        isSessionResponsive = false;
      }
    } else {
      isSessionResponsive = false;
    }

    return (
      <Modal
        key="DynamicQueueEntryActionModal.Modal"
        show={show}
        onHide={onHide}
      >
        <Modal.Header>
          <Row>
            <Col sm={8}>
              <h2>Manual Override</h2>
            </Col>
            <Col sm={4}>
              <h3 className="pull-right">#{queueEntry.shortCode}</h3>
            </Col>
          </Row>
          <p>Please use these functions with caution</p>
        </Modal.Header>
        <Modal.Body>
          <Button
            className="dynamic_queue_entry_action_modal__button"
            onClick={this.onForceSummonClick}
            disabled={
              queueEntry.state !==
                consts.DYNAMIC_QUEUE_ENTRY_STATE_IN_VIRTUAL_QUEUE ||
              !isSessionResponsive
            }
          >
            Force Summon
          </Button>
          <Button
            className="dynamic_queue_entry_action_modal__button"
            onClick={this.onServeClick}
            disabled={
              !consts.DYNAMIC_QUEUE_ENTRY_ACTIVE_STATES.includes(
                queueEntry.state
              ) ||
              consts.DYNAMIC_QUEUED_ENTRY_SERVED_STATES.includes(
                queueEntry.state
              )
            }
          >
            Serve
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export const DynamicQueueEntryActionModal = compose(
  graphql(forceSummonMutation, { name: "forceSummonMutation" }),
  graphql(serveMutation, { name: "serveMutation" })
)(DynamicQueueEntryActionModalView);

