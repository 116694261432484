import React from "react";
import { compose, graphql } from "react-apollo";
import gql from "graphql-tag";
import { Button, Modal, FormControl } from "react-bootstrap";
import dynamicQueueFragment from "../../queries/dynamicQueueFragment";

type OwnProps = {
  queueId: string;
  queueState: string;
  cancelledState: string;
};

type MagicProps = {
  cancelQueueMutation: (x: any) => Promise<any>;
  openQueueMutation: (x: any) => Promise<any>;
};

type Props = OwnProps & MagicProps;

type State = {
  showModal: boolean;
  confirmationTextInput: string;
};

class ToggleCancelQueueButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { showModal: false, confirmationTextInput: "" };
  }
  openModal = () => {
    this.setState({ showModal: true });
  };
  closeModal = () => {
    this.setState({ showModal: false });
  };
  onCancelConfirm = () => {
    this.props
      .cancelQueueMutation({
        variables: { queueId: this.props.queueId }
      })
      .then(() => {
        this.setState({ showModal: false, confirmationTextInput: "" });
      });
  };
  onOpenConfirm = () => {
    this.props.openQueueMutation({
      variables: { queueId: this.props.queueId }
    });
  };
  render() {
    return [
      this.props.queueState === this.props.cancelledState ? (
        <Button
          key="ToggleCancelQueueButton.Reopen"
          onClick={this.onOpenConfirm}
          bsStyle="dark"
        >
          Reopen Queue
        </Button>
      ) : (
        <Button
          key="ToggleCancelQueueButton.Cancel"
          onClick={this.openModal}
          bsStyle="dark"
        >
          Cancel Queue
        </Button>
      ),
      <Modal
        key="ToggleCancelQueueButton.Modal"
        show={this.state.showModal}
        onHide={this.closeModal}
      >
        <Modal.Body>
          <h2>Are you sure you want to cancel this queue?</h2>
          <div>
            <p>Type "cancel" below to cancel the queue</p>
            <FormControl
              value={this.state.confirmationTextInput}
              onChange={evt =>
                this.setState({ confirmationTextInput: (evt.target as any).value })
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.closeModal}>Don't cancel queue</Button>
          <Button
            bsStyle="danger"
            onClick={this.onCancelConfirm}
            disabled={
              this.state.confirmationTextInput.toLowerCase().trim() !== "cancel"
            }
          >
            Yes, I'm sure, cancel queue
          </Button>
        </Modal.Footer>
      </Modal>
    ];
  }
}

const cancelDynamicQueueMutation = gql`
  mutation cancelDynamicQueue($queueId: Uuid!) {
    cancelDynamicQueue(input: { queueId: $queueId }) {
      queue {
        ...dynamicQueue
      }
    }
  }

  ${dynamicQueueFragment}
`;

const openDynamicQueueMutation = gql`
  mutation openDynamicQueue($queueId: Uuid!) {
    openDynamicQueue(input: { queueId: $queueId }) {
      queue {
        ...dynamicQueue
      }
    }
  }

  ${dynamicQueueFragment}
`;

export const ToggleCancelDynamicQueueButton = compose(
  graphql(cancelDynamicQueueMutation, { name: "cancelQueueMutation" }),
  graphql(openDynamicQueueMutation, { name: "openQueueMutation" }),
)(ToggleCancelQueueButton) as React.ComponentType<OwnProps>;
