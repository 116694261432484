import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

type Props = {
  number: number,
  queueEntry?: {
    id: string;
    numberOfPeople?: number | null;
  };
};

export const PersonIcon = ({ number, queueEntry }: Props) => {
  return <>
    <OverlayTrigger
        trigger={["hover"]}
        overlay={(queueEntry && queueEntry.numberOfPeople) ? <Tooltip>{queueEntry.numberOfPeople}</Tooltip> : <div/>}
    >
      <span>
        {Array.from(new Array(number), (_, i) =>
            <svg
                key={i}
                width="10px"
                height="26px"
                viewBox="0 0 16 40"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
              <defs />
              <g id="-exports" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="icon-person-/-present" fill="#000000">
                  <path
                      d="M12,6.6 C12,8.80870733 10.2087073,10.6 8,10.6 C5.79129267,10.6 4,8.80870733 4,6.6 C4,4.39129267 5.79129267,2.6 8,2.6 C10.2087073,2.6 12,4.39129267 12,6.6 Z M11.7602097,11.8 L11.7602684,11.8 C13.2176349,11.8021789 14.3977064,12.9261072 14.4,14.3141311 L14.4,21.4442068 C14.3931367,23.009002 13.3734377,24.4101105 11.835882,24.9622584 L11.835882,34.9578848 C11.8335943,36.3044534 10.6901212,37.3934521 9.27862734,37.4 L6.71450936,37.4 C5.30295687,37.3934633 4.16183608,36.3044534 4.15725468,34.9578848 L4.15725468,24.9622584 C2.62198676,24.4079204 1.6045814,23.009002 1.6,21.4442068 L1.6,14.3141311 C1.6,12.9282862 2.77778959,11.8021845 4.23286833,11.8 L11.7602097,11.8 Z"
                      id="Combined-Shape"
                  />
                </g>
              </g>
            </svg>,
        )}
      </span>
    </OverlayTrigger>
  </>
}

