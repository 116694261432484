import React from "react";
import { compose, graphql } from "react-apollo";
import gql from "graphql-tag";
import { Button, Modal, FormControl, MenuItem, Dropdown } from "react-bootstrap";
import slottedQueueFragment from "../../queries/slottedQueueFragment";
import { CancelSlottedQueueMutationFn, SetSlottedQueueModeMutationFn, SlottedQueueMode } from "generated/graphql";

type SetModeAction = {
  kind: "mode",
  mode: SlottedQueueMode,
};

type CancelAction = {
  kind: "cancel",
};

type Action = {
  title: string,
  method: SetModeAction | CancelAction,
};

type Actions = {
  default: Action,
  alt: Action[],
}

function actionsForQueueMode(queueMode: SlottedQueueMode): Actions  {
  const pauseAction: Action = {
    title: "Pause queue",
    method: { kind: "mode", mode: SlottedQueueMode.Paused },
  };

  const unpauseAction: Action = {
    title: "Unpause queue",
    method: { kind: "mode", mode: SlottedQueueMode.Open },
  };

  const closeAction: Action = {
    title: "Close queue",
    method: { kind: "mode", mode: SlottedQueueMode.Closed },
  };

  const openAction: Action = {
    title: "Reopen queue",
    method: { kind: "mode", mode: SlottedQueueMode.Open },
  };

  const openPauseAction: Action = {
    title: "Reopen and pause queue",
    method: { kind: "mode", mode: SlottedQueueMode.Paused },
  };

  const closeAndCancelAction: Action = {
    title: "Close queue and cancel all entries",
    method: { kind: "cancel" },
  };

  const cancelAction: Action = {
    title: "Cancel all queue entries",
    method: { kind: "cancel" },
  };

  switch (queueMode) {
    case "PAUSED":
      return { default: unpauseAction, alt: [closeAction, closeAndCancelAction] };
    case "CLOSED":
      return { default: openAction, alt: [openPauseAction, cancelAction] };
    case "OPEN":
      return { default: pauseAction, alt: [closeAction, closeAndCancelAction] };
    default:
      throw new Error("impossible");
  }
}

type OwnProps = {
  queueId: string;
  queueMode: SlottedQueueMode;
};

type MagicProps = {
  cancelQueueMutation: CancelSlottedQueueMutationFn;
  setQueueModeMutation: SetSlottedQueueModeMutationFn;
};

type Props = OwnProps & MagicProps;

type State = {
  showCancelConfirmModal: boolean;
  confirmationTextInput: string;
};

class QueueModeSelector extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { showCancelConfirmModal: false, confirmationTextInput: "" };
  }
  openCancelConfirmModal() {
    this.setState({ showCancelConfirmModal: true });
  }
  closeCancelConfirmModal() {
    this.setState({ showCancelConfirmModal: false });
  }
  onCancelConfirm() {
    this.props
      .cancelQueueMutation({
        variables: { queueId: this.props.queueId }
      })
      .then(() => {
        this.setState({ showCancelConfirmModal: false, confirmationTextInput: "" });
      });
  }
  onAction(action: Action) {
    switch (action.method.kind) {
      case "mode":
        this.props.setQueueModeMutation({
          variables: { queueId: this.props.queueId, mode: action.method.mode }
        });
        break;
      case "cancel":
        this.openCancelConfirmModal();
        break;
    }
  }
  render() {
    let actions = actionsForQueueMode(this.props.queueMode);

    return (
      <>
        <Dropdown id={`queue-mode-dropdown-${this.props.queueId}`} className="btn-flex">
          <Button
            onClick={() => this.onAction(actions.default)}
            className={this.props.queueMode === "PAUSED" ? "flash-queue-paused" : undefined}
          >
            {actions.default.title}
          </Button>
          <Dropdown.Toggle />
          <Dropdown.Menu>
            {actions.alt.map((alt, i) => (
              <MenuItem key={i} onSelect={() => this.onAction(alt)}>
                {alt.title}
              </MenuItem>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Modal
          show={this.state.showCancelConfirmModal}
          onHide={() => this.closeCancelConfirmModal()}
        >
          <Modal.Body>
            <h2>Are you sure you want to cancel this queue?</h2>
            <div>
              <p>Type "cancel" below to cancel the queue</p>
              <FormControl
                value={this.state.confirmationTextInput}
                onChange={ev =>
                  this.setState({ confirmationTextInput: (ev.target as HTMLInputElement).value })
                }
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.closeCancelConfirmModal()}>Don't cancel queue</Button>
            <Button
              bsStyle="danger"
              onClick={() => this.onCancelConfirm()}
              disabled={
                this.state.confirmationTextInput.toLowerCase().trim() !== "cancel"
              }
            >
              Yes, I'm sure, cancel queue
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const cancelSlottedQueueMutation = gql`
  mutation cancelSlottedQueue($queueId: Uuid!) {
    cancelSlottedQueue(input: { queueId: $queueId }) {
      queue {
        ...slottedQueue
      }
    }
  }

  ${slottedQueueFragment}
`;

const setSlottedQueueModeMutation = gql`
  mutation setSlottedQueueMode($queueId: Uuid!, $mode: SlottedQueueMode!) {
    setSlottedQueueMode(input: { queueId: $queueId, mode: $mode }) {
      queue {
        ...slottedQueue
      }
    }
  }

  ${slottedQueueFragment}
`;

export default compose(
  graphql<OwnProps, any, any, Props>(cancelSlottedQueueMutation, { name: "cancelQueueMutation" }),
  graphql<OwnProps, any, any, Props>(setSlottedQueueModeMutation, { name: "setQueueModeMutation" }),
)(QueueModeSelector);
