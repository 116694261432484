import React from "react";
import { compose, graphql } from "react-apollo";
import gql from "graphql-tag";

import { MenuItem, Button, Modal } from "react-bootstrap";
import { SeatGroup } from "./utils";
import slottedQueueFragment from "../../queries/slottedQueueFragment";
import { SlottedQueueFragment, ReassignSlottedQueueEntryMutationFn, ReassignSlottedQueueTicketsMutationFn } from "generated/graphql";
import * as dateTime from "../../utils/dateTime";

type Slot = SlottedQueueFragment["todaysSlots"][0];

type ReassignConfirmationModalProps = {
  title: string,
  seatsRequired: number,
  queue: SlottedQueueFragment,
  currentSlot: Slot,
  allSlots: readonly Slot[],
  onResolve: (newSlotId: string | null) => void,
};

type ReassignConfirmationModalState = {
  slotId: string | null,
};

class ReassignConfirmationModal extends React.Component<ReassignConfirmationModalProps, ReassignConfirmationModalState> {
  constructor(props: ReassignConfirmationModalProps) {
    super(props);
    this.state = { slotId: null };
  }

  onCancel() {
    this.props.onResolve(null);
  }

  onSubmit(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault();
    this.props.onResolve(this.state.slotId);
  }

  onChange(ev: React.SyntheticEvent<HTMLSelectElement>) {
    let target = ev.target;
    let slotId = target instanceof HTMLSelectElement ? target.value : null;
    this.setState({ slotId });
  }

  render() {
    let slots = this.props.allSlots
      .filter(slot => !dateTime.isInPast(slot.startsAt))
      .filter(slot => dateTime.isBefore(slot.startsAt, this.props.queue.closeTimeToday))
      .filter(slot => slot.id !== this.props.currentSlot.id);

    return (
      <Modal show={true} onHide={() => this.onCancel()}>
        <form className="form" onSubmit={(ev) => this.onSubmit(ev)}>
          <Modal.Header>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>The user's device will be notified of their new slot time.</p>
            <select className="form-control" value={this.state.slotId || ""} onChange={(ev) => this.onChange(ev)}>
              <option value="" disabled>Select a slot</option>
              {slots.map((slot) => (
                <option
                  value={slot.id}
                  disabled={slot.availableSeatCount < this.props.seatsRequired}
                  key={slot.id}
                >
                  {dateTime.asTimeString(slot.startsAt)} - {slot.availableSeatCount} seat{slot.availableSeatCount === 1 ? "" : "s"} available
                </option>
              ))}
            </select>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.onCancel()}>Cancel</Button>
            <Button bsStyle="dark" type="submit">Reassign queue entry</Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

type OwnMenuProps = {
  readonly seatGroup: SeatGroup,
  readonly queue: SlottedQueueFragment,
  readonly currentSlot: Slot,
  readonly allSlots: readonly Slot[],
};
type MenuProps = OwnMenuProps & {
  mutateQueueEntry: ReassignSlottedQueueEntryMutationFn,
  mutateTickets: ReassignSlottedQueueTicketsMutationFn,
};
type MenuState = { currentModal?: DynModal };

type DynModal = object;

class SeatGroupActionMenuItems extends React.Component<MenuProps, MenuState> {
  constructor(props: MenuProps) {
    super(props);
    this.state = { currentModal: undefined };
  }

  setModal(modal: DynModal) {
    this.setState({ currentModal: modal });
  }

  clearModal() {
    this.setState({ currentModal: undefined });
  }

  reassign(newSlotId: string) {
    let queueEntry = this.props.seatGroup.queueEntry;

    if (queueEntry) {
      this.props.mutateQueueEntry({
        variables: {
          queueEntryId: queueEntry.id,
          newSlotId: newSlotId,
        }
      });
    } else {
      let ticketIds = [];

      for (let seat of this.props.seatGroup.seats) {
        if (seat.ticket) {
          ticketIds.push(seat.ticket.id);
        }
      }

      this.props.mutateTickets({
        variables: {
          ticketIds,
          newSlotId,
        }
      });
    }
  }

  showReassignSeatGroupModal() {
    let queueEntry = this.props.seatGroup.queueEntry;
    let seatsRequired = this.props.seatGroup.seats.length;

    let title;
    if (queueEntry) {
      title = `Select new slot for ${queueEntry.shortCode}`;
    } else {
      title = "Select new slot";
    }

    this.setModal(
      <ReassignConfirmationModal
        title={title}
        seatsRequired={seatsRequired}
        queue={this.props.queue}
        allSlots={this.props.allSlots}
        currentSlot={this.props.currentSlot}
        onResolve={(newSlot) => {
          if (newSlot) {
            this.reassign(newSlot);
          }

          this.clearModal();
        }}
      />
    );
  }

  render() {
    return (
      <>
        <MenuItem onSelect={() => this.showReassignSeatGroupModal()}>Reassign...</MenuItem>
        {this.state.currentModal}
      </>
    );
  }
}

const reassignSlottedQueueEntryMutation = gql`
  mutation reassignSlottedQueueEntry($queueEntryId: Uuid!, $newSlotId: Uuid!) {
    reassignSlottedQueueEntry(input: { queueEntryId: $queueEntryId, newSlotId: $newSlotId }) {
      queue {
        ...slottedQueue
      }
    }
  }

  ${slottedQueueFragment}
`;

const reassignSlottedQueueTicketsMutation = gql`
  mutation reassignSlottedQueueTickets($ticketIds: [Uuid!]!, $newSlotId: Uuid!) {
    reassignSlottedQueueTickets(input: { ticketIds: $ticketIds, newSlotId: $newSlotId }) {
      queue {
        ...slottedQueue
      }
    }
  }

  ${slottedQueueFragment}
`;

export default compose(
  graphql<OwnMenuProps, any, any, MenuProps>(reassignSlottedQueueEntryMutation, { name: "mutateQueueEntry" }),
  graphql<OwnMenuProps, any, any, MenuProps>(reassignSlottedQueueTicketsMutation, { name: "mutateTickets" }),
)(SeatGroupActionMenuItems);

// export default graphql<OwnMenuProps, any, any, MenuProps>(reassignSlottedQueueEntryMutation)(SeatGroupActionMenuItems);
