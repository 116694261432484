import gql from "graphql-tag";

export default gql`
  fragment activeQueueEntry on DynamicQueueEntry {
    id
    shortCode
    state
    stateChangedAt
    summonedEstimate
    session {
      id
    }
  }
`;
