import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

export type MutationErrorProps = {
  title: string,
  message?: string,
  error?: any,
  onClose: () => void,
};

export default function(props: MutationErrorProps) {
  const [show] = useState(true);
  return (
    <Modal show={show} onHide={() => props.onClose()}>
      <Modal.Header>
        <Modal.Title className="text-danger">{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.message ? <p>{props.message}</p> : null}
        {props.error ? (
          <details>
            <summary>Developer information</summary>
            <pre>{props.error.toString()}</pre>
          </details>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.onClose()}>Ok</Button>
      </Modal.Footer>
    </Modal>
  );
}
