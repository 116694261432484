import React from "react";
import { Button } from "react-bootstrap";

import { DYNAMIC_QUEUE_ENTRY_STATE_SUMMONED } from "../Queues/consts";
import { isDynamicQueueEntryServed } from "../Queues/utils";

import "./DynamicQueueEntryIndicatorButton.css";

const BUSY_COLOR = "#f8e71c";
const CANCELLED_COLOR = "#EA7B56";
const DARK_COLOR = "#343a40";
const DRAB_COLOR = "#868e96";
const FAR_COLOR = "#D1F1AE";
const LOBBY_COLOR = "#60B800";
const NEAR_COLOR = "#A0D566";

type Props = {
  isBusy: boolean;
  isInactive: boolean;
  lobbyLocationIds: readonly string[];
  locationIds: readonly string[];
  nearbyLocationIds: readonly string[];
  onClick: () => void;
  state: string;
};

export const DynamicQueueEntryIndicatorButton = ({
  isBusy,
  isInactive,
  lobbyLocationIds,
  locationIds,
  nearbyLocationIds,
  onClick = () => {},
  state
}: Props) => {
  let buttonColor = DRAB_COLOR;
  if (isBusy || isInactive) {
    buttonColor = BUSY_COLOR;
  } else if (state === DYNAMIC_QUEUE_ENTRY_STATE_SUMMONED) {
    if (locationIds.some(locationId => lobbyLocationIds.includes(locationId))) {
      buttonColor = LOBBY_COLOR;
    } else if (
      locationIds.some(locationId => nearbyLocationIds.includes(locationId))
    ) {
      buttonColor = NEAR_COLOR;
    } else {
      buttonColor = FAR_COLOR;
    }
  } else if (state === "USER_CANCELLED" || state === "EXPIRED") {
    buttonColor = CANCELLED_COLOR;
  } else if (isDynamicQueueEntryServed(state)) {
    buttonColor = DARK_COLOR;
  }

  return (
    <Button
      className="DynamicQueueEntryIndicatorButton"
      onClick={onClick}
      style={{
        backgroundColor: buttonColor,
        borderColor: buttonColor
      }}
    >
      &nbsp;
    </Button>
  );
};
