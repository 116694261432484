import gql from "graphql-tag";

export default gql`
  fragment slottedQueueTicketInfo on TessituraTicket {
    id
    ticketNumber
    order {
      id
      constituentEmail
    }
    slottedQueueEntry {
      id
    }
  }

  fragment slottedQueueEntry on SlottedQueueEntry {
    id
    createdAt
    shortCode
    numberOfPeople
    shortCode
    state
    tickets {
      ...slottedQueueTicketInfo
    }
    session {
      id
      isBusy
      metadataOsVersion
      metadataAppVersion
      metadataDeviceRole
      latestSessionInfo {
        createdAt
        batteryLevel
        locateSpaces {
          id
          name
        }
      }
      activeDynamicQueueEntries {
        id
        createdAt
        state
        queue {
          id
          name
        }
      }
      activeSlottedQueueEntries {
        id
        createdAt
        state
        queue {
          id
          name
        }
      }
    }
  }

  fragment slottedQueue on SlottedQueue {
    id
    name
    shortName
    mode
    title
    exhibitCapacityPeople
    closeTimeToday
    scheduledLastSlotStartTime
    scheduledLastSlotStartTimeToday
    slotIntervalSeconds
    servedTodayCount
    totalTicketsTodayCount
    claimedUnservedTicketsTodayCount
    remainingUnclaimedTicketsTodayCount
    remainingSeatsNext2HoursCount
    seatsRemainingTodayCount

    nearbyRegions {
      ... on SpaceRegion {
        ensoSpaceId
      }
    }

    todaysUnallocatedQueueEntries {
      ...slottedQueueEntry
    }

    todaysSlots {
      id
      startsAt
      mode
      startsAfterQueueCloseTime
      withinAllocationWindow
      notes
      reservedSeats
      maxReservableSeats
      availableSeatCount
      queueEntries {
        ...slottedQueueEntry
      }
      tickets {
        ...slottedQueueTicketInfo
      }
      unacknowledgedReassignedTickets {
        ticket {
          ...slottedQueueTicketInfo
        }
        newSlot {
          startsAt
        }
      }
      offeredJumps {
        id
        state
        offeredAt
        resolvedAt
        queueEntry {
          ...slottedQueueEntry
        }
      }
    }
  }
`;
