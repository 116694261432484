import moment from "moment";

export const asTimeString = (input: moment.MomentInput) => moment(input).format("LT");

// (sorts newest first)
export const dateSortFn = (input0: moment.MomentInput, input1: moment.MomentInput) => {
  if (input0 === input1) {
    return 0;
  } else if (moment(input0).isBefore(input1)) {
    return -1;
  }
  return 1;
}

export const isBefore = (input0: moment.MomentInput, input1: moment.MomentInput) => moment(input0).isBefore(input1);

export const isAfter = (input0: moment.MomentInput, input1: moment.MomentInput) => moment(input0).isAfter(input1);

export const isInPast = (input: moment.MomentInput) => moment(input).diff(moment()) < 0;

export const isInFuture = (input: moment.MomentInput) => moment(input).diff(moment()) > 0;

export const isLessThanXMinutesAgo = (input: moment.MomentInput, x: number) =>
  moment(input).isAfter(moment().subtract(x, "minutes"));

export const isInLessThanXMinutes = (input: moment.MomentInput, x: number) =>
  moment(input).isBefore(moment().add(x, "minutes"));

