import React from "react";
import { graphql } from "react-apollo";
import {
  Alert,
  Button,
  ControlLabel,
  FormControl,
  FormGroup,
  Modal,
  NavItem
} from "react-bootstrap";

import userInfoQuery from "../../queries/userInfo";

type OwnProps = {};

type MagicProps = {
  data: {
    loading: boolean;
    viewer: any;
  };
};

type Props = OwnProps & MagicProps;

type State = {
  showLoginModal: boolean;
  password: string;
  incorrectPassword: boolean;
};

class NavUserInfoView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showLoginModal: false,
      password: "",
      incorrectPassword: false
    };
  }
  private openModal = () => this.setState({ showLoginModal: true });

  private closeModal = () => this.setState({ showLoginModal: false });

  private onPasswordChange = (evt: any) => this.setState({ password: evt.target.value });

  private onFormSubmit = (evt: any) => {
    evt.preventDefault();

    fetch("/api/auth", {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ password: this.state.password })
    }).then(resp => {
      if (resp.status === 200) {
        window.location.reload();
      } else {
        this.setState({ incorrectPassword: true });
      }
    });
  };

  public render() {
    if (this.props.data.loading) {
      return null;
    }

    return this.props.data.viewer ? (
      <NavItem>Logged in</NavItem>
    ) : (
      [
        <NavItem key="NavUserInfo.NavItem" onClick={this.openModal}>
          Login
        </NavItem>,
        <Modal
          key="NavUserInfo.Modal"
          show={this.state.showLoginModal}
          onHide={this.closeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Login</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.onFormSubmit}>
              <FormGroup controlId="password">
                {this.state.incorrectPassword ? (
                  <Alert bsStyle="danger">
                    The entered password was incorrect, please try again
                  </Alert>
                ) : null}
                <ControlLabel>Password</ControlLabel>
                <FormControl
                  type="password"
                  value={this.state.password}
                  onChange={this.onPasswordChange}
                />
                <FormControl.Feedback />
              </FormGroup>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeModal}>Cancel</Button>
            <Button bsStyle="primary" onClick={this.onFormSubmit}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      ]
    );
  }
}

export const NavUserInfo = graphql(userInfoQuery)(NavUserInfoView as any);

