import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

type Props = {
  queueEntry?: {
    id: string;
    shortCode: string;
  };
};

export const EntryIdLabel = ({ queueEntry }: Props) => {
  const [copied, setCopied] = React.useState<boolean>(false);

  const onDoubleClick = () => {
    if (queueEntry && navigator.clipboard) {
      navigator.clipboard.writeText(queueEntry.id).then(() => {
        setCopied(true);
      });
    };
  }

  const tooltipText = copied
    ? "Copied!"
    : "Double click to copy queue entry uuid to clipboard";

  return (
    <OverlayTrigger
      trigger={["hover"]}
      overlay={!queueEntry ? <div /> : (
        <Tooltip>{tooltipText}</Tooltip>
      )}
      onExit={() => setCopied(false)}
    >
      <span onDoubleClick={onDoubleClick}>
        {queueEntry ? queueEntry.shortCode : "-"}
      </span>
    </OverlayTrigger>
  )
};

