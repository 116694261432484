import React from "react";

export const Help = () => {
  return (
    <div>
      <h2>Queue Manager Help</h2>
      <hr />
      <p>
        <strong>User Cancelled</strong> = Visitor left the queue
      </p>
      <p>
        <strong>Expired</strong> = Visitor was summoned on their device screen
        but took too long to get to the exhibit or they weren’t ‘Served’
        correctly
      </p>
      <p>
        <strong>Summoned</strong> (3 states based on distance)
      </p>
      <ul>
        <li>Dark green = at exhibit</li>
        <li>Mid green = within ~2 mins walk</li>
        <li>Light green = far</li>
      </ul>
      <p>
        <strong>Served</strong> = Visitor entered exhibit recently
      </p>
      <p>
        <strong>Completed</strong> = Visitor entered exhibit and more that the
        average exhibit view time has passed (ie. they entered Pausiris more
        than 4mins ago, Event Horizon more than 14mins ago)
      </p>
      <p>
        <strong>Busy</strong> = Visitor is currently in, or summoned for another
        exhibit
      </p>
      <p>
        <strong>Summon Pending</strong> = visitors device hasn’t connected to
        the queuer in over 5mins (asleep or dead battery) but if their device
        wakes they will be summoned
      </p>
      <p>
        <strong>Postponed</strong> = visitor has opted to postpone their summon
        for ~10mins. They remain at the front of the virtual queue but won’t be
        summoned for 10-15mins
      </p>
    </div>
  );
}

