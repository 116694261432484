import React, { useState, useEffect } from 'react';
import moment from 'moment/moment';

type Props = {
  // this should only be a format moment accepts but moments typing doesn't include this :/
  format?: string;
};

export const CurrentTime = ({ format = 'LT' }: Props) => {
  const [date, setDate] = useState(new Date());

  const tick = () => setDate(new Date());

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => {
      clearInterval(timerID);
    };
  });

  return <>{moment(date).format(format)}</>;
}

