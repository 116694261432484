import React from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { Button, Modal } from "react-bootstrap";
import TimePicker from "react-bootstrap-time-picker";

import dynamicQueueFragment from "../../queries/dynamicQueueFragment";
import slottedQueueFragment from "../../queries/slottedQueueFragment";
import { ChangeDynamicQueueCloseTimeMutationFn, ChangeSlottedQueueScheduledLastSlotStartTimeMutationFn } from "generated/graphql";

type OwnProps = {
  closeTime: string;
  queueId: string;
  stepSeconds?: number;
  label?: string;
};

type MagicProps = {
  mutate: ChangeDynamicQueueCloseTimeMutationFn | ChangeSlottedQueueScheduledLastSlotStartTimeMutationFn;
};

type Props = MagicProps & OwnProps;

type State = {
  closeTime: string;
  showModal: boolean;
};

class ChangeCloseTimeButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { showModal: false, closeTime: props.closeTime };
  }

  public componentDidReceiveProps(nextProps: Props) {
    if (nextProps.closeTime !== this.props.closeTime) {
      this.setState({ closeTime: nextProps.closeTime });
    }
  }

  private openModal = () => this.setState({ showModal: true });

  private closeModal = () => this.setState({ showModal: false });

  private onTimeChange = (time: number) => {
    let hours = Math.floor(time / 3600);
    let minutes = Math.floor((time % 3600) / 60);
    this.setState({
      closeTime: `${hours}:${minutes.toString().padStart(2, "0")}:00`,
    });
  };

  private onSubmit = () => {
    this.props.mutate({
      variables: {
        queueId: this.props.queueId,
        closeTime: this.state.closeTime,
      },
    });

    this.setState({ showModal: false });
  };

  public render = () => {
    const stepMinutes = this.props.stepSeconds ? this.props.stepSeconds / 60 : 15;
    const objectName = this.props.label || "Change Close Time";

    return (
      <>
        <Button key="ChangeCloseTimeButton.Button" bsStyle="dark" onClick={this.openModal}>{objectName}</Button>
        <Modal key="ChangeCloseTimeButton.Modal" show={this.state.showModal} onHide={this.closeModal}>
          <form className="form" onSubmit={this.onSubmit}>
            <Modal.Header>
              <Modal.Title>{objectName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <TimePicker
                start="12:00"
                end="23:59"
                step={stepMinutes}
                format={12}
                value={this.state.closeTime}
                onChange={this.onTimeChange}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.closeModal}>Cancel</Button>
              <Button bsStyle="danger" type="submit">Submit</Button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    )
  }
}


const changeDynamicQueueCloseTimeMutation = gql`
  mutation changeDynamicQueueCloseTime($queueId: Uuid!, $closeTime: NaiveTime!) {
    changeDynamicQueueCloseTime(input: { queueId: $queueId, closeTime: $closeTime }) {
      queue {
        ...dynamicQueue
      }
    }
 }

  ${dynamicQueueFragment}
`;
const changeSlottedQueueScheduledLastSlotStartTimeMutation = gql`
  mutation changeSlottedQueueScheduledLastSlotStartTime($queueId: Uuid!, $closeTime: NaiveTime!) {
    changeSlottedQueueScheduledLastSlotStartTime(input: { queueId: $queueId, startTime: $closeTime }) {
      queue {
        ...slottedQueue
      }
    }
  }

  ${slottedQueueFragment}
`;

export const ChangeDynamicQueueCloseTimeButton = graphql<OwnProps, any, any, Props>(changeDynamicQueueCloseTimeMutation)(ChangeCloseTimeButton);

export const ChangeSlottedQueueCloseTimeButton = graphql<OwnProps, any, any, Props>(changeSlottedQueueScheduledLastSlotStartTimeMutation)(ChangeCloseTimeButton);

