import React, {useCallback, useState} from "react";

import {
    Button,
    Col,
    Modal,
    FormGroup,
    FormControl,
    HelpBlock,
    ControlLabel,
    Grid
} from "react-bootstrap";

type PushNotificationPayload = {
    title: string;
    body: string;
};

type SentMetrics = {
    ios: number,
    android: number,
}

export const PushNotificationView = () => {
    // Configuration
    const notify_sending_api_endpoint = process.env.REACT_APP_NOTIFY_SENDING_API_ENDPOINT;
    const notify_sending_api_key = process.env.REACT_APP_NOTIFY_SENDING_API_KEY || '';

    // React State
    const [isSending, setIsSending] = useState(false);
    const [sentMetrics, setSentMetrics] = useState<SentMetrics>();
    const [titleField, setTitleField] = useState<string>("");
    const [bodyField, setBodyField] = useState<string>("");

    // Modal
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState("");
    const handleModalClose = () => setShowModal(false);

    const resetModal = () => {
        setShowModal(false)
        setModalTitle("")
        setModalContent("")
    }

    const showModalMessage = (content: string, title: string = "Alert") => {
        setModalTitle(title)
        setModalContent(content)
        setShowModal(true)
    }

    const fetchData = useCallback(async (payload: PushNotificationPayload) => {
        try {
            const response = await fetch(`${notify_sending_api_endpoint}sending/send`, {
                method: 'POST',
                headers: new Headers({
                    "x-api-key": notify_sending_api_key,
                }),
                body: JSON.stringify({
                    data: payload,
                })
            });

            if (!response.ok) {
                showModalMessage(`Request error: ${response.status} ${response.statusText}. No messages have been sent.`, "Error sending push notification")
                return
            }

            const metrics = await response.json()
            setSentMetrics({
                ios: metrics.ios,
                android: metrics.android,
            })
            showModalMessage("Messages sent:", "Sent messages!")
        } catch (e) {
            showModalMessage(`Error sending message: ${e.message}`, "Internal Error")
        }
    }, [notify_sending_api_endpoint, notify_sending_api_key])

    const handleFormSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault()
        resetModal()
        setIsSending(true)
        const payload: PushNotificationPayload = {
            title: titleField,
            body: bodyField
        }
        await fetchData(payload)
        setIsSending(false)
    };

    const sendingStatsContent = sentMetrics ? (
        <ul>
            <li>Android devices: {sentMetrics.android}</li>
            <li>iOS devices: {sentMetrics.ios}</li>
        </ul>
    ) : <></>


    const getTitleValidationState = () => {
        const length = titleField.length;
        if (length > 3 && length <= 24) return 'success';
        if (length > 24) return 'warning';
        return null;
    }

    const getBodyValidationState = () => {
        const length = bodyField.length;
        if (length > 3 && length <= 100) return 'success';
        if (length > 100) return 'warning';
        return null;
    }

    return (
        <div className="notify MessageView">
            <Grid>
                <Col md={12}>
                    <h1>Send Push Notifications</h1>
                </Col>
                <Col md={6}>
                    <p>
                        Use this form to send a push notification to all
                        on-site BYOD devices. Only visitors with The O
                        installed with push notifications enabled will see
                        this message.
                    </p>
                    <form onSubmit={handleFormSubmit}>
                        <FormGroup
                            controlId="formControlTitle"
                            validationState={getTitleValidationState()}
                        >
                            <ControlLabel>Title</ControlLabel>
                            <FormControl
                                type="text"
                                value={titleField}
                                onChange={event => setTitleField((event.target as HTMLInputElement).value)}
                            />
                            <FormControl.Feedback/>
                            <HelpBlock>Ideally less than 24 characters</HelpBlock>
                        </FormGroup>
                        <FormGroup
                            controlId="formControlTextarea"
                            validationState={getBodyValidationState()}
                        >
                            <ControlLabel>Message</ControlLabel>
                            <FormControl
                                componentClass="textarea"
                                value={bodyField}
                                onChange={event => setBodyField((event.target as HTMLInputElement).value)}
                            />
                            <FormControl.Feedback/>
                        </FormGroup>
                        <Button type={"submit"} disabled={isSending} bsStyle="primary">Send
                            push notification</Button>
                    </form>
                    <Modal show={showModal} onHide={handleModalClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>{modalTitle}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {modalContent}
                            {sendingStatsContent}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="button" onClick={handleModalClose}>
                                Bye!
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Col>
                <Col md={6} />
            </Grid>
        </div>
    );
};
