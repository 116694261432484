import React from "react";
import ToolTip from "react-portal-tooltip";

import "./AlertIcon.css";

type Props = {};

type State = {
  isTooltipActive: boolean;
};

export class AlertIcon extends React.Component<Props, State> {
  private parentRef: React.RefObject<SVGSVGElement>;

  constructor(props: Props) {
    super(props);
    this.state = { isTooltipActive: false };
    this.parentRef = React.createRef();
  }

  private showTooltip = () => {
    this.setState({ isTooltipActive: true });
  };

  private hideTooltip = () => {
    this.setState({ isTooltipActive: false });
  };

  public render() {
    const { children } = this.props;
    const toolTipProps = children
      ? {
          onMouseEnter: this.showTooltip,
          onMouseLeave: this.hideTooltip,
          onClick: this.showTooltip,
        }
      : {};
    return (
      <div className="AlertIcon">
        <svg
          {...toolTipProps}
          ref={this.parentRef}
          width="20px"
          height="20px"
          viewBox="0 0 40 40"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs />
          <g id="-exports" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="hud-icon-error" transform="translate(-8.000000, -8.000000)" fill="#F05A28">
              <g id="hud-icon-arror" transform="translate(8.000000, 8.000000)">
                <path
                  d="M20,40 C8.954305,40 0,31.045695 0,20 C0,8.954305 8.954305,0 20,0 C31.045695,0 40,8.954305 40,20 C40,31.045695 31.045695,40 20,40 Z M21.9231306,12.2453962 L21.9231306,6.57784598 L18.0343192,6.57784598 L18.0343192,12.2453962 L18.9934431,25.7777623 L21.0337612,25.7777623 L21.9231306,12.2453962 Z M21.7487444,32.1428571 L21.7487444,28.5679408 L18.1738281,28.5679408 L18.1738281,32.1428571 L21.7487444,32.1428571 Z"
                  id="Combined-Shape"
                />
              </g>
            </g>
          </g>
        </svg>
        {children &&
          <ToolTip
            style={{
              style: {
                color: '#FFF',
                background: 'rgba(0,0,0,.8)',
                padding: 5,
                maxWidth: '30rem',
              },
              arrowStyle: {
                color: 'rgba(0,0,0,.8)',
                borderColor: 'none',
              }
            }}
            active={this.state.isTooltipActive}
            position="left"
            arrow="center"
            parent={this.parentRef}
          >
            {children}
          </ToolTip>}
      </div>
    );
  }
}

