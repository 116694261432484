import React from "react";
import { Query } from "react-apollo";
import { match } from "react-router";
import { Route, Redirect } from "react-router-dom";
import { Grid, Nav, Navbar, Label } from "react-bootstrap";

import { DynamicQueueList } from "../Queues/DynamicQueue";
import { SlottedQueueList } from "../Queues/SlottedQueue";
import queuesQuery from "../../queries/queues";
import { NavItemLink } from "../NavItemLink";
import { NavUserInfo } from "../NavUserInfo";
import { Help } from "./Help";
import { LoadingGuard } from "../LoadingGuard";
import { assertNever } from "../../utils/helpers";

import { QueueListQuery } from "../../generated/graphql";
import {PushNotificationView} from "../Notify/PushNotificationView";
import {NamedroppingView} from "../Namedropping/NamedroppingView";

type QueueRouteProps = {
  match: match<{ queueId: string }>;
};

type Props ={
  data: QueueListQuery
};

type QueueType = QueueListQuery["dynamicQueues"][0] | QueueListQuery["slottedQueues"][0];

function queueModeLabel(queue: QueueType) {
  if (queue.__typename === "SlottedQueue") {
    if (queue.mode === "PAUSED") {
      return <Label bsStyle="warning">Paused</Label>;
    }
  }
}

export const AppView = ({ data }: Props) => {
  const queues = [ ...data.dynamicQueues, ...data.slottedQueues ];
  if (!queues.length) {
    return <div>No queues found.</div>;
  }
  const rootRoutePath = `/queues/${queues[0].id}`;

  return (
    <>
      <Navbar key="App.Navbar">
        <Navbar.Header>
          <Navbar.Brand>
            <a href="/">Queuer</a>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>

        <Navbar.Collapse>
          <Nav>
            {queues.map(queue => (
              <NavItemLink
                key={`App-NavItemLink-${queue.id}`}
                to={`/queues/${queue.id}`}
              >
                {queue.shortName}
                {queueModeLabel(queue)}
              </NavItemLink>
            ))}
            {data.viewer ? (
                <NavItemLink to="/notify">
                  Push Notifications
                </NavItemLink>
            ) : null}
            {/* Disable Namedropping */}
            {/*<NavItemLink to="/namedropping">*/}
            {/*  Namedropping*/}
            {/*</NavItemLink>*/}
          </Nav>
          <Nav pullRight>
            <NavItemLink to="/help">Help</NavItemLink>
            <NavUserInfo />
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Grid key="App.Grid">
        <Route
          path="/"
          exact
          key="App-Route-root"
          render={() => <Redirect to={rootRoutePath} />}
        />
        <Route path="/help" exact component={Help} />
        <Route path="/notify" exact component={PushNotificationView} />
        <Route path="/namedropping" exact component={NamedroppingView} />
        <Route
          path="/queues/:queueId"
          component={({ match }: QueueRouteProps) => <SplitByQueueType queues={queues} match={match} />}
        />
      </Grid>
    </>
  )
}

export const App = () => {
  // We should be able to pass data into LoadingGuard and have it return null
  // if data is undefined, that would save all of this boiler plate. Doesn't
  // work here. We have some similar working on mos-admin-web

  return (
    <Query<QueueListQuery, {}>
      query={queuesQuery}
      errorPolicy="all"
    >
      {({loading, error, data}) => (
        <LoadingGuard loading={loading} error={error}>
          {!!data ? <AppView data={data} /> : ""}
        </LoadingGuard>
      )}
    </Query>
  );
}

type SplitByQueueTypeProps = QueueRouteProps & {
  queues: ReadonlyArray<QueueListQuery["slottedQueues"][0] | QueueListQuery["dynamicQueues"][0]>;
};

const SplitByQueueType = ({ queues, match }: SplitByQueueTypeProps) => {
  const { queueId } = match.params;
  const queue = queues.find(q => q.id === match.params.queueId);

  if (!queue || !queue.__typename) {
    return <div>Queue not found</div>
  } else if (queue.__typename === "DynamicQueue") {
    return <DynamicQueueList queueId={queueId} />;
  } else if (queue.__typename === "SlottedQueue") {
    return <SlottedQueueList queueId={queueId} />;
  } else {
    assertNever(queue.__typename);
    return null;
  }
}
