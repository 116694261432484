import gql from "graphql-tag";

export default gql`
  fragment dynamicQueueEntry on DynamicQueueEntry {
    id
    shortCode
    state
    numberOfPeople
    stateChangedAt
    estimatedScore
    session {
      id
      isBusy
      metadataOsVersion
      metadataAppVersion
      metadataDeviceRole
      latestSessionInfo {
        createdAt
        batteryLevel
        locateSpaces {
          id
          name
        }
      }
    }
  }
`;
