import React from "react";
import { Tooltip, OverlayTrigger, Label } from "react-bootstrap";

import { Session, SessionInfo } from 'generated/graphql';

type Props = {
  session: {
    metadataOsVersion?: Session["metadataOsVersion"];
    metadataAppVersion?: Session["metadataAppVersion"];
    metadataDeviceRole?: Session["metadataDeviceRole"];
    latestSessionInfo?: {
      batteryLevel: SessionInfo["batteryLevel"];
    }
  }
};

export const BatteryLabel = ({ session }: Props) => {
  let batteryLevel;
  let batteryLevelPercent = "-";
  if (session.latestSessionInfo) {
    batteryLevel = session.latestSessionInfo.batteryLevel;
    batteryLevelPercent = batteryLevel ? `${(batteryLevel * 100).toFixed(0)}%` : 'N/A';
  }

  let batteryStatus = 'default';
  if (!batteryLevel) {
    batteryStatus = 'warning';
  } else if (batteryLevel <= 0.2) {
    batteryStatus = 'danger';
  }

  return (
    <OverlayTrigger
      trigger={["hover", "focus", "click"]}
      overlay={
        <Tooltip>
          <dl>
            <dt>iOS Version</dt>
            <dd>{session.metadataOsVersion || "?"}</dd>
            <dt>App Version</dt>
            <dd>{session.metadataAppVersion || "?"}</dd>
            <dt>Role</dt>
            <dd>{session.metadataDeviceRole || "?"}</dd>
          </dl>
        </Tooltip>
      }
    >
      <Label bsStyle={batteryStatus}>
        {batteryLevelPercent}
      </Label>
    </OverlayTrigger>
  )
};

