import React from "react";
import { Button, Modal } from "react-bootstrap";

type Props = {
  children: React.ReactChild | React.ReactChildren,
  lastQueryTimeStamp: string
};

export const StaleDataWarning = ({ children, lastQueryTimeStamp }: Props) => {
  const [timeoutId, setTimeoutId] = React.useState<number>();
  const [show, setShow] = React.useState<boolean>(false);
  const closeModal = () => setShow(false);

  // reset timeout and close modal after each new timestamp
  React.useEffect(() => {
    window.clearTimeout(timeoutId);
    const staleTime = 60 * 1000; // 1 minute
    const newTimeoutId = window.setTimeout(() => setShow(true), staleTime);
    setTimeoutId(newTimeoutId);
    setShow(false);
  }, [lastQueryTimeStamp]); // eslint-disable-line react-hooks/exhaustive-deps
  // Previous line is disabled as we don't want timeoutId in dependancy array
  // (it would cause an infinite loop). This is a special case for an otherwise
  // useful linting rule

  return (
    <>
      <Modal show={show} onHide={closeModal}>
        <Modal.Header>Warning</Modal.Header>
        <Modal.Body>
          Data is over 1 minute out of date - please refresh page.<br />
          If this message persists, your system time may be incorrect.
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal}>Dismiss</Button>
          <Button onClick={() => window.location.reload()} bsStyle="dark">Reload</Button>
        </Modal.Footer>
      </Modal>
      {children}
    </>
  );
};

