import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { BrowserRouter as Router } from "react-router-dom";

import { App } from "./components/App";
import registerServiceWorker from "./utils/registerServiceWorker";

import "bootstrap/dist/css/bootstrap.css";
import "./index.css";

const client = new ApolloClient({
  link: new HttpLink({ credentials: "same-origin" }), // defaults to using /graphql
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <Router>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Router>,
  document.getElementById("root")
);

registerServiceWorker();
