import moment from "moment";
import React from "react";
import { Query } from "react-apollo";
import { Button } from "react-bootstrap";

import { ChangeDynamicQueueCloseTimeButton } from "../ChangeCloseTimeButton";
import { ForceSummonButton } from "../ForceSummonButton";
import { ToggleCancelDynamicQueueButton } from "../ToggleCancelQueueButton";
import * as consts from "./consts";
import DynamicQueueEntry from "./DynamicQueueEntry";
import {
  isDynamicQueueEntryActive,
  isDynamicQueueEntryServed,
  getLocationIds,
} from "./utils";
import { CurrentTime } from "../CurrentTime";
import { LoadingGuard } from "../LoadingGuard";
import { StaleDataWarning } from "../StaleDataWarning";

import {
  DynamicQueueDetailDocument,
  DynamicQueueFragment,
  DynamicQueueDetailQuery,
  DynamicQueueDetailQueryVariables,
} from "generated/graphql";

import "./Queue.css";

type Props = {
  queue: DynamicQueueFragment;
};

const DynamicQueueView = ({ queue }: Props) => {
  const [showServedEntries, setShowServedEntries] = React.useState<boolean>(
    false
  );
  const toggleShowServedEntries = () =>
    setShowServedEntries(!showServedEntries);

  const lobbyLocationIds = getLocationIds(queue.lobbyRegions);
  const nearbyLocationIds = getLocationIds(queue.nearbyRegions);

  const displayedQueueEntries = !queue.todaysQueueEntries
    ? []
    : queue.todaysQueueEntries.filter((queueEntry) => {
        const isEntryUnder3MinsOld =
          moment().diff(queueEntry.stateChangedAt) < 3 * 60 * 1000;
        const isEntryIncomplete =
          queueEntry.state !== consts.DYNAMIC_QUEUE_ENTRY_STATE_COMPLETED;

        return (
          showServedEntries ||
          isDynamicQueueEntryActive(queueEntry.state) ||
          (isEntryUnder3MinsOld && isEntryIncomplete)
        );
      });

  return (
    <div className="queue DynamicQueue">
      <header>
        <div className="queue-preamble">
          <h1>{queue.name}</h1>
          <h2>{queue.state.toLowerCase()}</h2>
        </div>
        <div className="queue-metrics-and-actions">
          <div className="queue-metrics">
            <dl>
              <dt>Current Time</dt>
              <dd>
                <CurrentTime />
              </dd>
              <dt>Close Time</dt>
              <dd>{moment(`2000-01-01 ${queue.closeTime}`).format("LT")}</dd>
              <dt>Queued</dt>
              <dd>
                {queue.todaysQueueEntries
                  ? queue.todaysQueueEntries.filter((queueEntry) =>
                      isDynamicQueueEntryActive(queueEntry.state)
                    ).length
                  : "N/A"}
              </dd>
              <dt>Estimated Wait Time</dt>
              <dd>
                {queue.summonedEstimate != null
                  ? `${queue.summonedEstimate} minute${
                      queue.summonedEstimate !== 1 ? "s" : ""
                    }`
                  : "N/A"}
              </dd>
              <dt>Served today</dt>
              <dd>
                {queue.todaysQueueEntries
                  ? queue.todaysQueueEntries.filter((queueEntry) =>
                      isDynamicQueueEntryServed(queueEntry.state)
                    ).length
                  : "N/A"}
              </dd>
            </dl>
          </div>
        </div>
        <div className="queue-actions">
          <ToggleCancelDynamicQueueButton
            queueId={queue.id}
            queueState={queue.state}
            cancelledState="CANCELLED"
          />
          <ChangeDynamicQueueCloseTimeButton
            queueId={queue.id}
            closeTime={queue.closeTime}
          />
          <ForceSummonButton
            queueId={queue.id}
            todaysQueueEntries={queue.todaysQueueEntries}
          />
          <Button onClick={toggleShowServedEntries}>
            {showServedEntries ? "Hide Served Entries" : "Show Served Entries"}
          </Button>
        </div>
      </header>
      {queue.todaysQueueEntries ? (
        <table className="queue-data">
          <thead>
            <tr>
              <th className="queue-data-id">
                <div className="QueueEntryIndicatorButton">#</div>
              </th>
              <th>ID</th>
              <th>People</th>
              <th>Status</th>
              <th>Last location</th>
              <th>Battery level</th>
              <th>Last update</th>
            </tr>
          </thead>
          <tbody>
            {displayedQueueEntries
              .filter((queueEntry) => queueEntry.state === "SERVED")
              .map((queueEntry) => (
                <DynamicQueueEntry
                  key={queueEntry.id}
                  queueEntry={queueEntry}
                  lobbyLocationIds={lobbyLocationIds}
                  nearbyLocationIds={nearbyLocationIds}
                />
              ))}
          </tbody>
          <tbody>
            {displayedQueueEntries
              .filter((queueEntry) => queueEntry.state === "SUMMONED")
              .map((queueEntry) => (
                <DynamicQueueEntry
                  key={queueEntry.id}
                  queueEntry={queueEntry}
                  lobbyLocationIds={lobbyLocationIds}
                  nearbyLocationIds={nearbyLocationIds}
                />
              ))}
          </tbody>
          <tbody>
            {displayedQueueEntries
              .filter((queueEntry) => queueEntry.state === "SUMMON_PENDING")
              .map((queueEntry) => (
                <DynamicQueueEntry
                  key={queueEntry.id}
                  queueEntry={queueEntry}
                  lobbyLocationIds={lobbyLocationIds}
                  nearbyLocationIds={nearbyLocationIds}
                />
              ))}
          </tbody>
          <tbody>
            {displayedQueueEntries
              .filter(
                (queueEntry) =>
                  queueEntry.state === "IN_VIRTUAL_QUEUE" ||
                  queueEntry.state === "POSTPONED"
              )
              .map((queueEntry) => (
                <DynamicQueueEntry
                  key={queueEntry.id}
                  queueEntry={queueEntry}
                  lobbyLocationIds={lobbyLocationIds}
                  nearbyLocationIds={nearbyLocationIds}
                />
              ))}
          </tbody>
          <tbody>
            {displayedQueueEntries
              .filter(
                (queueEntry) =>
                  queueEntry.state === "COMPLETED" ||
                  queueEntry.state === "EXPIRED" ||
                  queueEntry.state === "USER_CANCELLED" ||
                  queueEntry.state === "QUEUE_CANCELLED"
              )
              .map((queueEntry) => (
                <DynamicQueueEntry
                  key={queueEntry.id}
                  queueEntry={queueEntry}
                  lobbyLocationIds={lobbyLocationIds}
                  nearbyLocationIds={nearbyLocationIds}
                />
              ))}
          </tbody>
        </table>
      ) : (
        "No queue entries available – perhaps you need to login?"
      )}
    </div>
  );
};

type DynamicQueueListProps = {
  queueId: string;
};

export const DynamicQueueList = ({ queueId }: DynamicQueueListProps) => {
  // We should be able to pass data into LoadingGuard and have it return null
  // if data is undefined, that would save all of this boiler plate. Doesn't
  // work here. We have some similar working on mos-admin-web

  return (
    <Query<DynamicQueueDetailQuery, DynamicQueueDetailQueryVariables>
      query={DynamicQueueDetailDocument}
      variables={{ id: queueId }}
      pollInterval={5000}
      errorPolicy="all"
    >
      {({ loading, error, data }) => (
        <LoadingGuard loading={loading} error={error}>
          <StaleDataWarning lastQueryTimeStamp={data && data.now}>
            {!!data && !!data.queue ? (
              <DynamicQueueView queue={data.queue} />
            ) : (
              ""
            )}
          </StaleDataWarning>
        </LoadingGuard>
      )}
    </Query>
  );
};
