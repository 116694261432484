import gql from "graphql-tag";
import dynamicQueueEntryFragment from "./dynamicQueueEntryFragment";

export default gql`
  fragment dynamicQueue on DynamicQueue {
    id
    name
    shortName
    closeTime
    state
    isFull
    lobbyRegions {
      ... on SpaceRegion {
        ensoSpaceId
      }
    }
    nearbyRegions {
      ... on SpaceRegion {
        ensoSpaceId
      }
    }
    summonedEstimate
    todaysQueueEntries {
      ...dynamicQueueEntry
    }
  }

  ${dynamicQueueEntryFragment}
`;
