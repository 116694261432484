import gql from "graphql-tag";

export default gql`
  query queueList {
    dynamicQueues {
      shortName
      id
    }
    slottedQueues {
      shortName
      id
      mode
    }
    viewer {
      sessionId
    }
  }
`;
