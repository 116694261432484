import React, {useState, useRef, useEffect} from "react";
import { Col, Grid, Panel } from "react-bootstrap";

type GalleryItem = {
  gallery: string;
  moderation_status: string;
  sign_text: string;
  price: number;
  created: number;
};

type GalleryCollection = {
  Red: GalleryItem[];
  Tan: GalleryItem[];
  Burgundy: GalleryItem[];
  Pink: GalleryItem[];
};

type intervalCallback = () => void;

/**
 * Taken from https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * @param callback
 * @param delay
 */
function useInterval(callback: intervalCallback, delay: number) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    // @ts-ignore
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      // @ts-ignore
      savedCallback.current();
    }

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const NamedroppingView = () => {
  const namedropping_api_endpoint = process.env.REACT_APP_NAMEDROPPING_API_ENDPOINT;

  const initialState = (): GalleryCollection => ({
    Red: [
      {
        gallery: "Red",
        moderation_status: "",
        sign_text: "Loading...",
        price: 0,
        created: 0
      }
    ],
    Tan: [
      {
        gallery: "Tan",
        moderation_status: "",
        sign_text: "Loading...",
        price: 0,
        created: 0
      }
    ],
    Burgundy: [
      {
        gallery: "Burgundy",
        moderation_status: "",
        sign_text: "Loading...",
        price: 0,
        created: 0
      }
    ],
    Pink: [
      {
        gallery: "Pink",
        moderation_status: "",
        sign_text: "Loading...",
        price: 0,
        created: 0
      }
    ]
  })

  const [galleries, setGalleries] = useState<GalleryCollection>(initialState);

  useInterval(async () => {
    try {
      const response = await fetch(`${namedropping_api_endpoint}galleries/transactions`);
      const galleries = await response.json()
      setGalleries(galleries)
    } catch (e) {
      console.error(`Error fetching galleries: ${e.message}`)
    }
  }, 3000); // 3 seconds

  return (
    <div>
      <Grid>
        <Col md={12}>
          <h1>Namedropping Naming Rights</h1>
        </Col>
        <Col md={6}>
          <NDGalleryItem gallery={galleries.Pink[0]}/>
          <NDGalleryItem gallery={galleries.Red[0]}/>
        </Col>
        <Col md={6}>
          <NDGalleryItem gallery={galleries.Tan[0]}/>
          <NDGalleryItem gallery={galleries.Burgundy[0]}/>
        </Col>
      </Grid>
    </div>
  );
};

type GalleryProps = {
  gallery: GalleryItem
}

export const NDGalleryItem = (props: GalleryProps) => {
  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title componentClass="h3">{props.gallery.gallery}</Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        <p>{props.gallery.sign_text}</p>
        <p>${props.gallery.price / 100}.00</p>
        <p>{props.gallery.moderation_status}</p>
      </Panel.Body>
    </Panel>
  )
}
