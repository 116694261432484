import React from "react";
import { Button, Modal, FormControl } from "react-bootstrap";

type Props = {
  actionName: string;
  objectName: string;
  show: boolean;
  onConfirm: () => void;
  onHide: () => void;
};

export const ConfirmDeleteModal = ({ actionName, objectName, show, onConfirm, onHide }: Props) => {
  const [confirmInput, setConfirmInput] = React.useState<string>('');

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setConfirmInput('');
    onConfirm();
  }


  return (
    <Modal onHide={onHide} show={show}>
      <form onSubmit={onSubmit}>

        <Modal.Body>
          <h2>Are you sure you want to {actionName} this {objectName}?</h2>
          <div>
            <p>Type "{actionName}" below to {actionName} the {objectName}</p>
            <FormControl
              value={confirmInput}
              onChange={e => setConfirmInput((e.target as any).value)}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={onHide}>Don't {actionName} {objectName}</Button>
          <Button
            type="submit"
            bsStyle="danger"
            disabled={confirmInput.toLowerCase().trim() !== actionName}
          >
            Yes, I'm sure, {actionName} {objectName}
          </Button>
        </Modal.Footer>

      </form>
    </Modal>
  );
}

