import React from "react";
import { Alert } from "react-bootstrap";

export const ApiError = () => {
  return (
    <Alert bsStyle="danger">
      Unable to fetch latest queue status. Please refresh the page
    </Alert>
  );
}

