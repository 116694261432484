import moment from "moment/moment";
import React from "react";
import { get, has } from "lodash";

import { AlertIcon } from "../Icons/AlertIcon";
import { PersonIcon } from "../Icons/PersonIcon";
import { DynamicQueueEntryIndicatorButton } from "../DynamicQueueEntryIndicatorButton";
import { getDynamicQueueEntryStateHuman } from "./utils";
import { DynamicQueueEntryActionModal } from "../DynamicQueueEntryActionModal";
import { BatteryLabel } from "../BatteryLabel";
import { EntryIdLabel } from "../EntryIdLabel";

import { DynamicQueueEntryFragment } from "generated/graphql";

type Props = {
  queueEntry: DynamicQueueEntryFragment;
  lobbyLocationIds: ReadonlyArray<string>;
  nearbyLocationIds: ReadonlyArray<string>;
};

type State = {
  showModal: boolean;
};

class DynamicQueueEntry extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { showModal: false };
  }

  public render = () => {
    const { queueEntry, lobbyLocationIds, nearbyLocationIds } = this.props;

    const lastResponsiveAt =
      queueEntry && queueEntry.session.latestSessionInfo
        ? moment(queueEntry.session.latestSessionInfo.createdAt)
        : moment(0); // FIXME this should be undefined

    const currentSpaces =
      queueEntry && queueEntry.session.latestSessionInfo
        ? queueEntry.session.latestSessionInfo.locateSpaces
        : [];

    let stateLabel = getDynamicQueueEntryStateHuman(queueEntry.state);
    let isBusy = false;
    let isInactive = false;
    if (queueEntry.state === "IN_VIRTUAL_QUEUE" && queueEntry.session.isBusy) {
      isBusy = true;
      stateLabel = "Busy";
    } else if (queueEntry.state === "SUMMON_PENDING") {
      isInactive = true;
    }

    return (
      <>
        <tr
          key={`QueueEntry-${queueEntry.id}`}
          data-dynamic-queue-entry-id={queueEntry.id}
          data-session-id={get(queueEntry, "session.id")}
        >
          <td>
            <DynamicQueueEntryIndicatorButton
              state={queueEntry.state}
              isBusy={isBusy}
              isInactive={isInactive}
              locationIds={currentSpaces.map((locateSpace) => locateSpace.id)}
              lobbyLocationIds={lobbyLocationIds}
              nearbyLocationIds={nearbyLocationIds}
              onClick={() =>
                this.setState({ showModal: !this.state.showModal })
              }
            />
          </td>
          <td>
            <EntryIdLabel queueEntry={queueEntry} />
          </td>
          <td>
            <PersonIcon queueEntry={queueEntry} number={queueEntry.numberOfPeople} />
          </td>
          <td>{stateLabel}</td>
          <td>
            {currentSpaces
              ? currentSpaces.map((locateSpace) => locateSpace.name).join(", ")
              : "N/A"}
          </td>
          <td>
            <BatteryLabel session={queueEntry.session} />
          </td>
          <td>
            {has(queueEntry, "session.latestSessionInfo.createdAt")
              ? [
                  lastResponsiveAt.format("LT"),
                  lastResponsiveAt.isBefore(
                    moment().subtract(5, "minutes")
                  ) && (
                    <AlertIcon>
                      <p>
                        Last response: {lastResponsiveAt.fromNow()}
                        <br />
                        Reported battery level:{" "}
                        {Number(
                          get(
                            queueEntry,
                            "session.latestSessionInfo.batteryLevel"
                          ) * 100
                        ).toFixed(0)}
                        %
                      </p>
                      <p>
                        This O doesn’t appear to be on so the data shown is not
                        be up-to-date.
                      </p>
                    </AlertIcon>
                  ),
                ]
              : "N/A"}
          </td>
        </tr>
        <DynamicQueueEntryActionModal
          show={this.state.showModal}
          onHide={() => {
            this.setState({ showModal: false });
          }}
          queueEntry={queueEntry}
        />
      </>
    );
  };
}

export default DynamicQueueEntry;
