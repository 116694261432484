
// https://www.typescriptlang.org/docs/handbook/advanced-types.html#exhaustiveness-checking
export function assertNever(x: never): never {
  throw new Error('Unexpected object: ' + x);
}

// Simplified version of lodash's pick; avoids the temptation to use lodash's
// type-clobbering path expression syntax.
//
// XXX(bw): this doesn't work properly with TS 3.3 if you attempt to use a
// wrapper function. We tried to create an 'actionsPick' function that curried
// in the 'actions' arg as 'v', but the resulting type did not correctly infer
// the picked keys.
export function pick<T, K extends keyof T>(v: T, ...keys: K[]): Pick<T, K> {
  const out: any = {};
  for (const key of keys) {
    out[key] = v[key];
  }
  return out;
}

// This shouldn't need to exist, but TypeScript can't even maintain the consistency
// of its own compromises so it's a bit of a stretch to think they'd bother to find
// a better way to support something like this:
// https://github.com/Microsoft/TypeScript/pull/12253#issuecomment-263132208
export function objectKeys<T extends object>(v: T): Array<keyof T> {
  return Object.keys(v) as Array<keyof T>;
}

