import _ from "lodash";
import moment from "moment";
import React from "react";
import classNames from "classnames";
import { Mutation, Query } from "react-apollo";
import { Button, Tabs, Tab, Panel, Table, Label, DropdownButton } from "react-bootstrap";
import gql from "graphql-tag";

import * as dateTime from "../../utils/dateTime";
import { ChangeSlottedQueueCloseTimeButton } from "../ChangeCloseTimeButton";
import { getSeatGroups, getSlotState } from "./utils";
import { CurrentTime } from "../CurrentTime";
import { LoadingGuard } from "../LoadingGuard";
import { StaleDataWarning } from "../StaleDataWarning";
import { BatteryLabel } from "../BatteryLabel";
import { PersonIcon } from "../Icons/PersonIcon";
import { ConfirmDeleteModal } from "../ConfirmDeleteModal";
import { BusyLabel } from "../BusyLabel";
import { EntryIdLabel } from "../EntryIdLabel";
import { SetNotesReservationsButton } from "../SetNotesReservationsButton";
import SeatGroupActionMenuItems from "./SeatGroupActionMenuItems";
import SlottedQueueModeSelector from "../SlottedQueues/QueueModeSelector";
import ModeSelector from "./ModeSelector";

import {
  SlottedQueueDetailDocument,
  SlottedQueueFragment,
  SlottedQueueDetailQuery,
  SlottedQueueDetailQueryVariables,
  OpenSlottedQueueSlotMutation,
  OpenSlottedQueueSlotMutationFn,
  OpenSlottedQueueSlotMutationVariables,
  CancelSlottedQueueSlotMutation,
  CancelSlottedQueueSlotMutationFn,
  CancelSlottedQueueSlotMutationVariables,
} from 'generated/graphql';

import "./Queue.css";

// "computed" types from graphql schema
type Slot = SlottedQueueFragment["todaysSlots"][0];
type SlottedQueueEntry = Slot["queueEntries"][0];

const getLatestSessionInfo = (queueEntry?: SlottedQueueEntry) =>
  queueEntry ? queueEntry.session.latestSessionInfo : undefined;

const LastLocation = ({ queueEntry }: { queueEntry?: SlottedQueueEntry }) => {
  const sessionInfo = getLatestSessionInfo(queueEntry);
  return (
    <span>
      {!sessionInfo ? "-" : sessionInfo.locateSpaces.map(space => space.name).join(", ")}
    </span>
  );
}

const LastUpdated = ({ queueEntry }: { queueEntry?: SlottedQueueEntry }) => {
  const sessionInfo = getLatestSessionInfo(queueEntry);
  return <span>{!sessionInfo ? "-" : dateTime.asTimeString(sessionInfo.createdAt)}</span>;
}

type SlotCardProps = {
  readonly slot: Slot;
  readonly numberOfPeople: number;
  readonly isOpen: boolean;
  readonly onToggle: () => void;
  readonly cancelSlot: CancelSlottedQueueSlotMutationFn;
  readonly openSlot: OpenSlottedQueueSlotMutationFn;
  readonly cancelSlotLoading: boolean;
  readonly queue: SlottedQueueFragment;
};

const SlotStateLabel = ({ slot }: { slot: Slot }) => {
  let stateText = getSlotState(slot);

  let style;
  switch (stateText) {
    case "Paused":
      style = "warning";
      break;
    case "Cancelled":
      style = "danger";
      break;
  }

  return (<Label bsStyle={style}>{stateText}</Label>);
};

const SlotCard = ({ slot, numberOfPeople, isOpen, onToggle, cancelSlot, openSlot, cancelSlotLoading, queue }: SlotCardProps) => {
  const [showConfirmModal, setShowConfirmModal] = React.useState<boolean>(false);

  const cancelThisSlot = () => {
    cancelSlot({ variables: { slotId: slot.id } })
      .then(() => setShowConfirmModal(false));
  };

  const seatGroups = getSeatGroups(slot, numberOfPeople);
  const allocatedSeatCount = _.sum(seatGroups
    .filter(seatGroup => seatGroup.state === "Allocated" || seatGroup.state === "Served" || seatGroup.state === "Ticketed" || seatGroup.state === "Reserved")
    .map(seatGroup => seatGroup.seats.length));

  const isPastSlotStartTime =
    moment().subtract(5, "minutes") > moment(slot.startsAt);

  const slotTimeString = dateTime.asTimeString(slot.startsAt);

  return (
    <Panel>
      <div className="slotcard-header" onClick={onToggle}>
        <div className="slotcard-col">
          <h4>{slotTimeString}</h4>
        </div>
        <div className="slotcard-col slotcard-notes-short">
          { slot.reservedSeats === 0 ? null : (
            <Label bsStyle="info">
              {slot.reservedSeats} seat{slot.reservedSeats === 1 ? "" : "s"} reserved
            </Label>
          ) }
          { isOpen ? null : (<em>{slot.notes}</em>) }
        </div>
        <div className="slotcard-col text-right">
          <span>{allocatedSeatCount} / {numberOfPeople} seats</span>
        </div>
        <div className="slotcard-col text-right">
          <SlotStateLabel slot={slot} />
        </div>
      </div>
      <div className={classNames({"slotcard-body": true, "slotcard-open": isOpen})}>
        { slot.notes !== "" ? (
          <p className="slotcard-notes">
            {slot.notes.split("\n").map((line, i) => (
              <span key={i}>{line}<br /></span>
            ))}
          </p>
        ) : null }
        <Table>
          <thead
            key="thead"
            className={classNames({"past-slot-start-time": isPastSlotStartTime})}
          >
            <tr>
              <th>Status</th>
              <th>ID</th>
              <th>Ticket #</th>
              <th>Email</th>
              <th>Last location</th>
              <th>Battery level</th>
              <th>Last update</th>
              <th></th>
            </tr>
          </thead>
          <tbody
            key="tbody"
            className={classNames({"past-slot-start-time": isPastSlotStartTime})}
          >
            {seatGroups.map((seatGroup, groupIndex) => (
              seatGroup.seats.map((seat, seatIndex) => (
                <tr key={`${slot.id}-${groupIndex}-${seatIndex}`} className={(groupIndex % 2) ? "striped-slotted-seat": ""}>
                  <td>{seatGroup.state}</td>
                  <td><EntryIdLabel queueEntry={seatGroup.queueEntry} /></td>
                  <td>{seat.ticket ? seat.ticket.ticketNumber : "-"}</td>
                  <td>{seat.ticket ? seat.ticket.order.constituentEmail : "-"}</td>
                  <td>
                    <LastLocation queueEntry={seatGroup.queueEntry} />
                  </td>
                  <td>
                    {seatGroup.queueEntry ? <BatteryLabel session={seatGroup.queueEntry.session} /> : '-'}
                  </td>
                  <td>
                    <LastUpdated queueEntry={seatGroup.queueEntry} />
                  </td>
                  { seatIndex === 0 ? (
                    <td className="slotted-seat-actions-cell">
                      { seatGroup.state === "Allocated" || seatGroup.state === "Ticketed" ? (
                        <DropdownButton title="&#x22ef;" bsSize="xsmall" id={`slot-seat-actions-${slot.id}-${groupIndex}-${seatIndex}`}>
                          <SeatGroupActionMenuItems
                            seatGroup={seatGroup}
                            queue={queue}
                            currentSlot={slot}
                            allSlots={queue.todaysSlots}
                          />
                        </DropdownButton>
                      ) : null }
                    </td>
                  ) : (
                    <td className="slotted-seat-actions-padding">
                    </td>
                  ) }
                </tr>
              ))
            ))}
            {slot.unacknowledgedReassignedTickets.map(reassigned => (
              <tr key={`reassigned-${reassigned.ticket.id}`} className="reassigned-slotted-seat">
                <td>Reassigned</td>
                <td>-</td>
                <td>{reassigned.ticket.ticketNumber}</td>
                <td>{reassigned.ticket.order.constituentEmail}</td>
                <td colSpan={4} className="reassigned-note">New slot time at {dateTime.asTimeString(reassigned.newSlot.startsAt)}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="slotcard-actions">
          <ModeSelector slot={slot} />

          <SetNotesReservationsButton
            slotId={slot.id}
            notes={slot.notes}
            reservedSeats={slot.reservedSeats}
            maxReservableSeats={slot.maxReservableSeats}
            title={`Set notes and seat reservations: ${queue.name} ${slotTimeString}`}
          />
        </div>

        <ConfirmDeleteModal
          actionName="cancel"
          objectName="queue slot"
          show={showConfirmModal}
          onConfirm={cancelThisSlot}
          onHide={() => setShowConfirmModal(false)}
        />
      </div>
    </Panel>
  );
}

type OtherQueueEntriesTableProps = {
  showStatusColumn: boolean;
  queueEntries: Slot["queueEntries"];
};

const OtherQueueEntriesTable = ({ queueEntries, showStatusColumn }: OtherQueueEntriesTableProps) => {
  return (
    <table className="queue-data">
      <thead>
        <tr>
          <th>ID</th>
          <th>People</th>
          {showStatusColumn ? <th>Status</th> : null}
          <th>Last location</th>
          <th>Battery level</th>
          <th>Time joined</th>
          <th>Last update</th>
        </tr>
      </thead>
      <tbody>
        {queueEntries.map(queueEntry => (
          <tr>
            <td><EntryIdLabel queueEntry={queueEntry} /></td>
            <td><PersonIcon number={queueEntry.numberOfPeople} /></td>
            {showStatusColumn ? <td><BusyLabel queueEntry={queueEntry} /></td> : null}
            <td>
              <LastLocation queueEntry={queueEntry} />
            </td>
            <td>
              <BatteryLabel session={queueEntry.session} />
            </td>
            <td>{dateTime.asTimeString(queueEntry.createdAt)}</td>
            <td>
              <LastUpdated queueEntry={queueEntry} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

type OwnProps = {
  queue: SlottedQueueFragment;
};

type ConnectedProps = {
  cancelSlot: CancelSlottedQueueSlotMutationFn;
  openSlot: OpenSlottedQueueSlotMutationFn;
  cancelSlotLoading: boolean;
};

type SlottedQueueViewProps = OwnProps & ConnectedProps;

const SlottedQueueView = ({ queue, cancelSlot, openSlot, cancelSlotLoading }: SlottedQueueViewProps) => {
  const [showPastEntries, setShowPastEntries] = React.useState<boolean>(false);
  const toggleShowPastEntries = () => setShowPastEntries(!showPastEntries);

  const [openSections, setOpenSections] = React.useState<Record<string, boolean>>({});
  const toggleOpenSecion = (id: string) => () => {
    // set current id as true we currently only allow one open
    return setOpenSections({ [id]: !openSections[id] });
  }

  // open next available time slot on first render
  React.useEffect(() => {
    const futureSlots = queue.todaysSlots.filter(slot =>
      moment(slot.startsAt).diff(moment()) > 0);

    const sortedFutureSlots = futureSlots.sort((slot0, slot1) =>
      dateTime.dateSortFn(slot0.startsAt, slot1.startsAt));

    const nextSlot = sortedFutureSlots.length > 0 ? sortedFutureSlots[0] : undefined;
    if (nextSlot) { setOpenSections({[nextSlot.id]: true}); }
    }, []); // eslint-disable-line

  const slotsBeforeClosing = queue.todaysSlots.filter(slot => dateTime.isBefore(slot.startsAt, queue.closeTimeToday));
  const remainingSlots = slotsBeforeClosing.filter(slot => dateTime.isLessThanXMinutesAgo(slot.startsAt, 20));
  const todaysSlots = showPastEntries ? slotsBeforeClosing : remainingSlots;

  const waitingEntries = queue.todaysUnallocatedQueueEntries.filter(entry =>
      entry.state !== "USER_CANCELLED" && entry.state !== "QUEUE_CANCELLED");
  const waitingCount = waitingEntries.reduce((sum, entry) => sum + entry.numberOfPeople, 0);

  const userCancelledEntries = queue.todaysUnallocatedQueueEntries.filter(entry =>
      entry.state === "USER_CANCELLED");
  const userCancelledCount = userCancelledEntries.reduce((sum, entry) => sum + entry.numberOfPeople, 0);

  const queueCancelledEntries = queue.todaysUnallocatedQueueEntries.filter(entry =>
      entry.state === "QUEUE_CANCELLED");
  const queueCancelledCount = queueCancelledEntries.reduce((sum, entry) => sum + entry.numberOfPeople, 0);

  // let modeLabelText = queue.mode.charAt(0).toUpperCase() + queue.mode.slice(1).toLowerCase();
  let modeLabelStyle =
    queue.mode === "OPEN"   ? "success" :
    queue.mode === "CLOSED" ? "danger" :
    queue.mode === "PAUSED" ? "warning" : "default";

  const modeLabelText = queue.mode.toLowerCase();

  return (
    <div className="queue SlottedQueue">
      <header>
        <div className="queue-preamble">
          <h1>{queue.name}</h1>
          <h2><Label bsStyle={modeLabelStyle}>{modeLabelText}</Label></h2>
        </div>
        <div className="queue-metrics-and-actions">
          <div className="queue-metrics">
            <dl>
              <dt>Current Time</dt>
              <dd>
                <CurrentTime />
              </dd>
              <dt>Last Time</dt>
              <dd>{dateTime.asTimeString(queue.scheduledLastSlotStartTimeToday)}</dd>
              <dt>Served Today</dt>
              <dd>{queue.servedTodayCount}</dd>
              <dt>Claimed Unserved Tickets</dt>
              <dd>{queue.totalTicketsTodayCount > 0 ? queue.claimedUnservedTicketsTodayCount: "N/A"}</dd>
              <dt>Remaining Unclaimed Tickets</dt>
              <dd>{queue.totalTicketsTodayCount > 0 ? queue.remainingUnclaimedTicketsTodayCount: "N/A"}</dd>
              <dt>Remaining Seats (Next 2 Hours)</dt>
              <dd>{queue.remainingSeatsNext2HoursCount > 2 ? queue.remainingSeatsNext2HoursCount : "Full"}</dd>
              <dt>Remaining Seats (Today)</dt>
              <dd>{queue.seatsRemainingTodayCount > 2 ? queue.seatsRemainingTodayCount : "Full"}</dd>
            </dl>
          </div>
          <div className="queue-actions">
            <SlottedQueueModeSelector
              queueId={queue.id}
              queueMode={queue.mode}
            />
            <ChangeSlottedQueueCloseTimeButton
              queueId={queue.id}
              closeTime={queue.scheduledLastSlotStartTime}
              stepSeconds={queue.slotIntervalSeconds}
              label="Change Last Slot Time"
            />
            <Button onClick={toggleShowPastEntries}>
              {showPastEntries ? "Hide Past Entries" : "Show Past Entries"}
            </Button>
          </div>
        </div>
      </header>
      <Tabs className="slotted-queue-tabs" animation={false}>
        <Tab className="slotted-queue-tab" eventKey="slots" title="Slots">
          <table className="queue-data">
            {todaysSlots.map(slot => (
              <SlotCard
                key={slot.id}
                slot={slot}
                numberOfPeople={queue.exhibitCapacityPeople}
                isOpen={!!openSections[slot.id]}
                onToggle={toggleOpenSecion(slot.id)}
                cancelSlot={cancelSlot}
                openSlot={openSlot}
                cancelSlotLoading={cancelSlotLoading}
                queue={queue}
              />
            ))}
          </table>
        </Tab>
        <Tab
          className="slotted-queue-tab"
          eventKey="waiting"
          title={`Waiting (${waitingCount})`}
        >
          <OtherQueueEntriesTable queueEntries={waitingEntries} showStatusColumn={true} />
        </Tab>
        <Tab
          className="slotted-queue-tab"
          eventKey="cancelled"
          title={`Cancelled (${queueCancelledCount}, ${userCancelledCount})`}
        >
          <h4 style={{ margin: '40px 0 16px 0', fontWeight: 'bold' }}>
            Queue entries affected by a queue cancellation
          </h4>
          <OtherQueueEntriesTable queueEntries={queueCancelledEntries} showStatusColumn={false} />
          <h4 style={{ margin: '40px 0 16px 0', fontWeight: 'bold' }}>
            User cancelled queue entries
          </h4>
          <OtherQueueEntriesTable queueEntries={userCancelledEntries} showStatusColumn={false} />
        </Tab>
      </Tabs>
    </div>
  );
}

const openSlottedQueueSlotMutation = gql`
  mutation openSlottedQueueSlot($slotId: Uuid!) {
    setSlottedQueueSlotMode(input: { slotId: $slotId, mode: DEFAULT }) {
      slot {
        id
        mode
      }
    }
  }
`;

const cancelSlottedQueueSlotMutation = gql`
  mutation cancelSlottedQueueSlot($slotId: Uuid!) {
    setSlottedQueueSlotMode(input: { slotId: $slotId, mode: CANCELLED }) {
      slot {
        id
        mode
      }
    }
  }
`;

const SlottedQueueListWithMutations = ({ queue }: OwnProps) => {
  return (
    <Mutation<CancelSlottedQueueSlotMutation, CancelSlottedQueueSlotMutationVariables>
      mutation={cancelSlottedQueueSlotMutation}
    >
      {(cancelSlot, cancelSlotResult) => (
        <Mutation<OpenSlottedQueueSlotMutation, OpenSlottedQueueSlotMutationVariables>
          mutation={openSlottedQueueSlotMutation}
        >
          {(openSlot, openSlotResult) => (
            <SlottedQueueView
              queue={queue}
              cancelSlot={cancelSlot}
              openSlot={openSlot}
              cancelSlotLoading={cancelSlotResult.loading || openSlotResult.loading}
            />
          )}
        </Mutation>
      )}
    </Mutation>
  );
}

type SlottedQueueProps = {
  queueId: string;
};

export const SlottedQueueList = ({ queueId }: SlottedQueueProps) => {
  // We should be able to pass data into LoadingGuard and have it return null
  // if data is undefined, that would save all of this boiler plate. Doesn't
  // work here. We have some similar working on mos-admin-web

  return (
    <Query<SlottedQueueDetailQuery, SlottedQueueDetailQueryVariables>
      query={SlottedQueueDetailDocument}
      variables={{id: queueId}}
      pollInterval={5000}
      errorPolicy="all"
    >
      {({loading, error, data}) => (
        <LoadingGuard loading={loading} error={error}>
          <StaleDataWarning lastQueryTimeStamp={data && data.now}>
            {!!data && !!data.queue ? <SlottedQueueListWithMutations  queue={data.queue} /> : ""}
          </StaleDataWarning>
        </LoadingGuard>
      )}
    </Query>
  );
}

