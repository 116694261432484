import React, { useState } from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { SplitButton, MenuItem } from "react-bootstrap";

import slottedQueueFragment from "../../queries/slottedQueueFragment";
import MutationError from "../MutationError";
import { SlottedQueueFragment, SetSlotModeMutationFn, SlotMode } from 'generated/graphql';

type Slot = SlottedQueueFragment["todaysSlots"][0];

type Action = {
  title: string,
  mode: SlotMode,
};

type Actions = {
  default: Action,
  alt: Action,
}

function actionsForSlotMode(slotMode: SlotMode): Actions  {
  const pauseAction: Action = {
    title: "Pause slot",
    mode: SlotMode.Paused,
  };

  const unpauseAction: Action = {
    title: "Unpause slot",
    mode: SlotMode.Default,
  };

  const cancelAction: Action = {
    title: "Cancel slot and auto-reassign seats",
    mode: SlotMode.Cancelled,
  };

  const openAction: Action = {
    title: "Open slot",
    mode: SlotMode.Default,
  };

  const openPausedAction: Action = {
    title: "Reopen and pause",
    mode: SlotMode.Paused,
  };

  switch (slotMode) {
    case "PAUSED":
      return { default: unpauseAction, alt: cancelAction };
    case "CANCELLED":
      return { default: openAction, alt: openPausedAction };
    default:
      return { default: pauseAction, alt: cancelAction };
  }
}

type OwnProps = {
  slot: Slot,
};

type Props = OwnProps & { mutate: SetSlotModeMutationFn };

function SlotModeButton(props: Props) {
  const actions = actionsForSlotMode(props.slot.mode);
  const [error, setError] = useState();

  const mutate = function(mode: SlotMode) {
    props.mutate({
      variables: {
        slotId: props.slot.id,
        mode: mode,
      },
    }).catch(e => {
      if (e.graphQLErrors[0].message.startsWith("CannotAutoReassignPresale")) {
        setError(<MutationError
          title="Failed to auto-reassign seats"
          message="Please manually reassign seats before cancelling this slot"
          onClose={() => setError(null)}
        />);
      } else {
        setError(<MutationError
          error={error}
          title="Failed to change slot mode"
          onClose={() => setError(null)}
        />);
      }
    });
  }

  return (
    <>
      <SplitButton
        id={`slot-mode-${props.slot.id}`}
        title={actions.default.title}
        onClick={() => mutate(actions.default.mode)}
      >
        <MenuItem onSelect={() => mutate(actions.alt.mode)}>
          {actions.alt.title}
        </MenuItem>
      </SplitButton>
      {error}
    </>
  );
}

const setSlotModeMutation = gql`
  mutation setSlotMode($slotId: Uuid!, $mode: SlotMode!) {
    setSlottedQueueSlotMode(input: { slotId: $slotId, mode: $mode }) {
      slot {
        queue {
          ...slottedQueue
        }
      }
    }
  }

  ${slottedQueueFragment}
`;

export default graphql<OwnProps, any, any, Props>(setSlotModeMutation)(SlotModeButton);
