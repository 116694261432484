import React from "react";
import { match } from "react-router";
import { Link, Route } from "react-router-dom";
import { Location, LocationDescriptor } from "history";

/**
 * A Bootstrap <NavItemLink> which knows if it's "active" or not.
 *
 * This is mostly just borrowed from React Router's <NavLink> component.
 */
type Props = {
  to: LocationDescriptor;
  exact?: boolean;
  strict?: boolean;
  location?: Location;
  activeClassName?: string;
  className?: string;
  activeStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  isActive?: (m: match, l: Location) => void;
  'aria-current'?: 'page' | 'step' | 'location' | 'date' | 'time' | 'true';
  children?: any;
};

type ChildProps = {
  match: match;
  location: Location;
};

export const NavItemLink = ({
  to,
  exact,
  strict,
  location,
  activeClassName,
  className,
  activeStyle,
  style,
  isActive: getIsActive,
  "aria-current": ariaCurrent,
  ...rest
}: Props) => {
  const path = typeof to === "object" ? to.pathname : to;

  // Regex taken from: https://github.com/pillarjs/path-to-regexp/blob/master/index.js#L202
  const escapedPath = path && path.replace(/([.+*?=^!:${}()[\]|/\\])/g, "\\$1");

  return (
    <Route
      path={escapedPath}
      exact={exact}
      strict={strict}
      location={location}
      children={({ match, location }: ChildProps) => {
        const isActive = !!(getIsActive ? getIsActive(match, location) : match);

        return (
          <li
            role="presentation"
            className={isActive ? [className, activeClassName].filter(i => i).join(" ") : className}
          >
            <Link
              to={to}
              className={isActive ? [className, activeClassName].filter(i => i).join(" ") : className}
              style={isActive ? { ...style, ...activeStyle } : style}
              aria-current={isActive ? ariaCurrent : "false"}
              {...rest}
            />
          </li>
        );
      }}
    />
  );
};

NavItemLink.defaultProps = {
  activeClassName: "active",
  "aria-current": "true",
};

