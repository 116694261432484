import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

import * as dateTime from "../../utils/dateTime"
import {
  Session,
  QueueEntry,
  SlottedQueue,
  SlottedQueueEntry,
  SlottedQueueEntryState,
  DynamicQueue,
  DynamicQueueEntry,
  DynamicQueueEntryState,
} from 'generated/graphql';

type SessionFragmet = {
  readonly id: Session["id"];
  readonly isBusy: Session["isBusy"];
  readonly activeSlottedQueueEntries: ReadonlyArray<{
    readonly id: SlottedQueueEntry["id"];
    readonly createdAt: SlottedQueueEntry["createdAt"];
    readonly state: SlottedQueueEntry["state"];
    readonly queue: Pick<SlottedQueue, "id" | "name">;
  }>;
  readonly activeDynamicQueueEntries: ReadonlyArray<{
    readonly id: DynamicQueueEntry["id"];
    readonly createdAt: DynamicQueueEntry["createdAt"];
    readonly state: DynamicQueueEntry["state"];
    readonly queue: Pick<DynamicQueue, "id" | "name">;
  }>;
};

type Props = {
  readonly queueEntry: Pick<QueueEntry, "id" | "createdAt"> & {
    readonly session: SessionFragmet;
    readonly state: SlottedQueueEntryState | DynamicQueueEntryState;
  }
};

export const BusyLabel = ({ queueEntry }: Props) => {
  const { session, state } = queueEntry;
  // get list of other queues in session
  let otherQueueEntries = [
    ...session.activeSlottedQueueEntries,
    ...session.activeDynamicQueueEntries,
  ].filter(entry => entry.id !== queueEntry.id);

  // this order doesn't seem to be deterministic; sort to prevent re-ordering
  otherQueueEntries = otherQueueEntries.sort((q0, q1) => {
    if (q0.queue.name === q1.queue.name) return 0;
    if (q0.queue.name < q1.queue.name) return -1;
    return 1;
  });

  return (
    <OverlayTrigger
      trigger={["hover", "focus", "click"]}
      overlay={
        <Tooltip id={`busy-info-for-sesssion-${session.id}`}>
          <div className="busy-info-tooltip">
            <dl>
              <dt>Other queues:</dt>
              {otherQueueEntries.map(entry => (
                <div className="tooltip-pad-bottom" key={entry.queue.id}>
                  <dd>{entry.queue.name}</dd>

                  <div className="tooltip-row">
                    <dd>Joined:</dd>
                    <dd>{dateTime.asTimeString(entry.createdAt)}</dd>
                  </div>

                  <div className="tooltip-row">
                    <dd>Postponed:</dd>
                    <dd>{entry.state === "POSTPONED" ? "Yes" : "No"}</dd>
                  </div>
                </div>
              ))}
            </dl>
          </div>
        </Tooltip>
      }
    >
      <span>{session.isBusy ? "Busy" : state}</span>
    </OverlayTrigger>
  )
};

