import React from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { Button, Modal } from "react-bootstrap";

import { DynamicQueueFragment } from 'generated/graphql';
import activeQueueEntryFragment from "../../queries/activeQueueEntryFragment";

const forceSummonMutation = gql`
  mutation forceSummonNextDynamicQueueEntry($queueId: Uuid!) {
    forceSummonNextDynamicQueueEntry(input: { queueId: $queueId }) {
      queueEntry {
        ...activeQueueEntry
      }
    }
  }

  ${activeQueueEntryFragment}
`;

type OwnProps = {
  readonly queueId: DynamicQueueFragment["id"];
  readonly todaysQueueEntries: DynamicQueueFragment["todaysQueueEntries"];
};

type MagicProps = {
  readonly mutate: (x : any) => Promise<any>;
};

type Props = OwnProps & MagicProps;

type State = {
  readonly showModal: boolean;
};

class ForceSummonButtonView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { showModal: false };
  }
  private openModal = () => this.setState({ showModal: true });

  private closeModal = () => this.setState({ showModal: false });

  private onForceSummonConfirm = () => {
    this.props.mutate({
      variables: { queueId: this.props.queueId }
    });
    this.setState({ showModal: false });
  };

  public render = () => {
    let inVirtualQueueCount = this.props.todaysQueueEntries
      ? this.props.todaysQueueEntries.filter(
        queueEntry => queueEntry.state === "IN_VIRTUAL_QUEUE"
      ).length
      : 0;

    return (
      <>
        <Button
          key="ForceSummonButton.Button"
          onClick={this.openModal}
          disabled={inVirtualQueueCount === 0}
        >
          Summon +1
      </Button>
        <Modal
          key="ForceSummonButton.Modal"
          show={this.state.showModal}
          onHide={this.closeModal}
        >
          <Modal.Body>
            <h2>Are you sure you want to summon one additional queue member?</h2>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeModal}>Cancel</Button>
            <Button bsStyle="danger" onClick={this.onForceSummonConfirm}>
              Summon
        </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export const ForceSummonButton = graphql<OwnProps, any, any, Props>(forceSummonMutation)(ForceSummonButtonView);

