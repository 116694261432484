export const DYNAMIC_QUEUE_ENTRY_STATE_UNQUEUED = "UNQUEUED";
export const DYNAMIC_QUEUE_ENTRY_STATE_IN_VIRTUAL_QUEUE = "IN_VIRTUAL_QUEUE";
export const DYNAMIC_QUEUE_ENTRY_STATE_SUMMON_PENDING = "SUMMON_PENDING";
export const DYNAMIC_QUEUE_ENTRY_STATE_SUMMONED = "SUMMONED";
export const DYNAMIC_QUEUE_ENTRY_STATE_POSTPONED = "POSTPONED";
export const DYNAMIC_QUEUE_ENTRY_STATE_SERVED = "SERVED";
export const DYNAMIC_QUEUE_ENTRY_STATE_COMPLETED = "COMPLETED";
export const DYNAMIC_QUEUE_ENTRY_STATE_QUEUE_CANCELLED = "QUEUE_CANCELLED";
export const DYNAMIC_QUEUE_ENTRY_STATE_EXPIRED = "EXPIRED";
export const DYNAMIC_QUEUE_ENTRY_STATE_USER_CANCELLED = "USER_CANCELLED";

export const DYNAMIC_QUEUE_ENTRY_STATES = [
  DYNAMIC_QUEUE_ENTRY_STATE_UNQUEUED,
  DYNAMIC_QUEUE_ENTRY_STATE_IN_VIRTUAL_QUEUE,
  DYNAMIC_QUEUE_ENTRY_STATE_SUMMON_PENDING,
  DYNAMIC_QUEUE_ENTRY_STATE_SUMMONED,
  DYNAMIC_QUEUE_ENTRY_STATE_POSTPONED,
  DYNAMIC_QUEUE_ENTRY_STATE_SERVED,
  DYNAMIC_QUEUE_ENTRY_STATE_COMPLETED,
  DYNAMIC_QUEUE_ENTRY_STATE_QUEUE_CANCELLED,
  DYNAMIC_QUEUE_ENTRY_STATE_EXPIRED,
  DYNAMIC_QUEUE_ENTRY_STATE_USER_CANCELLED,
];

export const DYNAMIC_QUEUE_ENTRY_STATE_HUMAN: Map<string, string> = new Map([
  [DYNAMIC_QUEUE_ENTRY_STATE_UNQUEUED, "Unqueued"],
  [DYNAMIC_QUEUE_ENTRY_STATE_IN_VIRTUAL_QUEUE, "Waiting"],
  [DYNAMIC_QUEUE_ENTRY_STATE_SUMMON_PENDING, "Summon Pending"],
  [DYNAMIC_QUEUE_ENTRY_STATE_SUMMONED, "Summoned"],
  [DYNAMIC_QUEUE_ENTRY_STATE_POSTPONED, "Postponed"],
  [DYNAMIC_QUEUE_ENTRY_STATE_SERVED, "Served"],
  [DYNAMIC_QUEUE_ENTRY_STATE_COMPLETED, "Completed"],
  [DYNAMIC_QUEUE_ENTRY_STATE_QUEUE_CANCELLED, "Queue Cancelled"],
  [DYNAMIC_QUEUE_ENTRY_STATE_EXPIRED, "Expired"],
  [DYNAMIC_QUEUE_ENTRY_STATE_USER_CANCELLED, "User Cancelled"],
]);

export const DYNAMIC_QUEUE_ENTRY_ACTIVE_STATES = [
  DYNAMIC_QUEUE_ENTRY_STATE_IN_VIRTUAL_QUEUE,
  DYNAMIC_QUEUE_ENTRY_STATE_SUMMON_PENDING,
  DYNAMIC_QUEUE_ENTRY_STATE_SUMMONED,
  DYNAMIC_QUEUE_ENTRY_STATE_POSTPONED,
  DYNAMIC_QUEUE_ENTRY_STATE_SERVED,
];

export const DYNAMIC_QUEUED_ENTRY_SERVED_STATES = [
  DYNAMIC_QUEUE_ENTRY_STATE_SERVED,
  DYNAMIC_QUEUE_ENTRY_STATE_COMPLETED,
];
