import _ from "lodash";
import * as dateTime from "../../utils/dateTime";
import { SlottedQueueFragment } from 'generated/graphql';

import {
  DYNAMIC_QUEUE_ENTRY_ACTIVE_STATES,
  DYNAMIC_QUEUED_ENTRY_SERVED_STATES,
  DYNAMIC_QUEUE_ENTRY_STATE_HUMAN
} from "./consts";


type Slot = SlottedQueueFragment["todaysSlots"][0];
type SlottedQueueEntry = Slot["queueEntries"][0];
type Ticket = SlottedQueueEntry["tickets"][0];

export const getSlottedSeatState = (slot: Slot, queueEntry?: SlottedQueueEntry, hasTicket?: boolean, offered?: Slot["offeredJumps"][0]) => {
  // {{{ some queueEntry states mapped 1:1 (with formatting)
  if (queueEntry && queueEntry.state === 'NO_SHOWED') {
    return 'No show';
  }
  if (queueEntry && queueEntry.state === 'ALLOCATED') {
    return 'Allocated';
  }
  if (queueEntry && queueEntry.state === 'SERVED') {
    return 'Served';
  }
  // }}}

  // Ticketed means has ticket but no queueEntry (it's unclaimed)
  if (!queueEntry && hasTicket) {
    return 'Ticketed';
  }

  // {{{ slot level statuses
  if (slot.mode === "CANCELLED") {
    return "Cancelled";
  }
  if (dateTime.isInPast(slot.startsAt)) {
    return "Closed";
  }
  // }}}

  // if an offered object is passed then a jump has been offered
  if (offered) {
    return "Jump offered";
  }

  // if no other state is assigned and could be allocated the the seat is available
  if (slot.withinAllocationWindow) {
    return "Available";
  }

  return "Unallocated";
}

export type SeatGroup = {
  readonly state: ReturnType<typeof getSlottedSeatState> | "Reserved";
  readonly queueEntry?: SlottedQueueEntry;
  readonly seats: Seat[];
};

export type Seat = {
  readonly ticket?: Ticket;
};

export const isDynamicQueueEntryActive = (state: string) =>
  DYNAMIC_QUEUE_ENTRY_ACTIVE_STATES.includes(state);

export const isDynamicQueueEntryServed = (state: string) =>
  DYNAMIC_QUEUED_ENTRY_SERVED_STATES.includes(state);

// Given a queue state, return it's human readable name.
export const getDynamicQueueEntryStateHuman = (state: string) =>
  DYNAMIC_QUEUE_ENTRY_STATE_HUMAN.get(state);

// Given an array of BeaconRegions return a list of location ids (ensoSpaceIds).
export const getLocationIds = (regions?: ReadonlyArray<{ ensoSpaceId: string }> | null) => {
  if (regions) {
    return regions
      .filter(region => region.ensoSpaceId)
      .map(region => region.ensoSpaceId);
  }
  return [];
}

export const getSlotState = (slot: Slot) => {
  if (slot.mode === "CANCELLED") {
    return "Cancelled";
  }

  if (slot.mode === "PAUSED") {
    return "Paused";
  }

  if (dateTime.isInPast(slot.startsAt)) {
    return "Closed";
  }

  if (slot.withinAllocationWindow) {
    return "Available";
  }

  return "Unallocated";
}

export const getSeatGroups = (slot: Slot, numberOfPeople: number) => {
  let seatGroups: SeatGroup[] = [];
  let totalSeatCount: number = 0;

  for (const queueEntry of slot.queueEntries) {
    if (queueEntry.tickets.length > 0) {
      let seats = [];

      for (const ticket of queueEntry.tickets) {
        // claimed ticketed seats
        seats.push({ ticket });

        totalSeatCount++;
      }

      seatGroups.push({
        state: getSlottedSeatState(slot, queueEntry, true),
        queueEntry: queueEntry,
        seats: seats,
      });
    } else {
      let seats = [];

      for (let i = queueEntry.numberOfPeople; i > 0; i--) {
        // regular unpaid queue entries
        seats.push({ ticket: undefined });

        totalSeatCount++;
      }

      seatGroups.push({
        state: getSlottedSeatState(slot, queueEntry),
        queueEntry: queueEntry,
        seats: seats,
      })
    }
  }

  for (const offeredJump of slot.offeredJumps) {
    let seats: Seat[];

    if (offeredJump.queueEntry.tickets.length > 0) {
      seats = offeredJump.queueEntry.tickets.map((t) => ({ ticket: t }));
    } else {
      seats = _.times(offeredJump.queueEntry.numberOfPeople, () => ({ ticket: undefined }));
    }

    totalSeatCount += seats.length;

    seatGroups.push({
      state: getSlottedSeatState(slot, offeredJump.queueEntry, false, offeredJump),
      queueEntry: offeredJump.queueEntry,
      seats
    });
  }

  for (const ticketGroup of _.values(_.groupBy(slot.tickets, t => t.order.id))) {
    let seats = [];

    for (const ticket of ticketGroup) {
      if (!ticket.slottedQueueEntry) {
        // unclaimed tickets
        seats.push({
          ticket: ticket,
        });

        totalSeatCount++;
      }
    }

    if (seats.length > 0) {
      seatGroups.push({
        state: getSlottedSeatState(slot, undefined, true),
        queueEntry: undefined,
        seats: seats,
      });
    }
  }

  // reserved seat group:
  {
    let seats = [];

    for (let i = 0; i < slot.reservedSeats; i++) {
      seats.push({ ticket: undefined });

      totalSeatCount++;
    }

    if (seats.length > 0) {
      seatGroups.push({
        state: "Reserved",
        queueEntry: undefined,
        seats: seats,
      });
    }
  }

  // fill out available seats:
  {
    let seats = [];

    while (totalSeatCount < numberOfPeople) {
      // available seats
      seats.push({ ticket: undefined });

      totalSeatCount++;
    }

    if (seats.length > 0) {
      seatGroups.push({
        state: getSlottedSeatState(slot),
        queueEntry: undefined,
        seats: seats,
      });
    }
  }

  return seatGroups;
}
